/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
footer {
  position: relative;
  display: flex; }
  footer .logo {
    max-width: 150px; }
  footer .scroll-top-anchor {
    height: 100%;
    background-color: #ffffff;
    padding-top: 320px; }
    @media (max-width: 1199px) {
      footer .scroll-top-anchor {
        display: none; } }
    footer .scroll-top-anchor .scroll-top-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border: none;
      padding: 65px 30px; }
      footer .scroll-top-anchor .scroll-top-button:before {
        display: block;
        content: '';
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMjMgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5hcnJvdy1yaWdodDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhcnJvdy1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuNTAwMDAwLCAxOC4wMDAwMDApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTExLjUwMDAwMCwgLTE4LjAwMDAwMCkgdHJhbnNsYXRlKC02LjAwMDAwMCwgNy4wMDAwMDApIiBmaWxsPSIjODE3QjcxIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkZpbGwtMSIgcG9pbnRzPSIyNC44MzcwODU0IDAgMjQuMDEzODM1MiAwLjgyNTU3ODk2NyAzMi43NzEyODYgMTAuNDkxNTAyNiAwIDEwLjQ5MTUwMjYgMCAxMS42NTkyMjM5IDMyLjc3MTI4NiAxMS42NTkyMjM5IDI0LjAxMzgzNTIgMjEuMTc0NDIxIDI0LjgzNzA4NTQgMjIgMzUgMTEuMDc1MzYzMyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 22px;
        height: 35px;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
      footer .scroll-top-anchor .scroll-top-button span {
        display: block;
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71;
        text-transform: uppercase;
        text-orientation: sideways-right;
        -webkit-writing-mode: vertical-lr;
            -ms-writing-mode: tb-lr;
                writing-mode: vertical-lr;
        -webkit-transform: rotate(180deg) translateY(0);
                transform: rotate(180deg) translateY(0);
        margin-top: 20px;
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
      footer .scroll-top-anchor .scroll-top-button:focus, footer .scroll-top-anchor .scroll-top-button:active {
        outline: none; }
      footer .scroll-top-anchor .scroll-top-button:hover {
        cursor: pointer; }
        footer .scroll-top-anchor .scroll-top-button:hover:before {
          -webkit-transform: translateY(-7.5px);
                  transform: translateY(-7.5px); }
        footer .scroll-top-anchor .scroll-top-button:hover span {
          -webkit-transform: rotate(180deg) translateY(7.5px);
                  transform: rotate(180deg) translateY(7.5px); }
  @media (min-width: 1200px) {
    footer .scroll-top-anchor-tablet {
      display: none; } }
  footer .scroll-top-anchor-tablet button {
    width: 100%;
    padding: 20px 40px;
    font-size: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    outline: none; }
    footer .scroll-top-anchor-tablet button:after {
      display: block;
      content: '';
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMjMgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5hcnJvdy1yaWdodDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhcnJvdy1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuNTAwMDAwLCAxOC4wMDAwMDApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTExLjUwMDAwMCwgLTE4LjAwMDAwMCkgdHJhbnNsYXRlKC02LjAwMDAwMCwgNy4wMDAwMDApIiBmaWxsPSIjODE3QjcxIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkZpbGwtMSIgcG9pbnRzPSIyNC44MzcwODU0IDAgMjQuMDEzODM1MiAwLjgyNTU3ODk2NyAzMi43NzEyODYgMTAuNDkxNTAyNiAwIDEwLjQ5MTUwMjYgMCAxMS42NTkyMjM5IDMyLjc3MTI4NiAxMS42NTkyMjM5IDI0LjAxMzgzNTIgMjEuMTc0NDIxIDI0LjgzNzA4NTQgMjIgMzUgMTEuMDc1MzYzMyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 22px;
      height: 35px;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  footer .footer-right {
    flex: 1 1; }
  footer .footer-main {
    padding: 25px 20px 80px;
    background-color: #004F6E; }
    @media (max-width: 1079px) {
      footer .footer-main {
        padding: 40px 20px 50px; } }
    @media (max-width: 399px) {
      footer .footer-main {
        padding: 40px 0 50px; } }
    footer .footer-main .wrap .footer-row {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start; }
      footer .footer-main .wrap .footer-row.top-row {
        margin-bottom: 60px; }
        @media (max-width: 1079px) {
          footer .footer-main .wrap .footer-row.top-row {
            display: none; } }
      footer .footer-main .wrap .footer-row.middle-row {
        margin-bottom: 20px; }
        footer .footer-main .wrap .footer-row.middle-row .contact-details-grid {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          min-width: 810px;
          opacity: 0;
          -webkit-transition: opacity 1s;
          transition: opacity 1s; }
          footer .footer-main .wrap .footer-row.middle-row .contact-details-grid.visible {
            opacity: 1; }
          @media (max-width: 1079px) {
            footer .footer-main .wrap .footer-row.middle-row .contact-details-grid {
              min-width: 100%;
              margin-top: 20px;
              margin-bottom: 10px; } }
          footer .footer-main .wrap .footer-row.middle-row .contact-details-grid > div {
            padding: 16px 25px 20px;
            border: 1px solid #ffffff;
            flex: 1 1;
            display: flex;
            justify-content: center;
            flex-direction: column; }
            @media (max-width: 959px) {
              footer .footer-main .wrap .footer-row.middle-row .contact-details-grid > div {
                min-width: 100%; } }
            @media (min-width: 960px) {
              footer .footer-main .wrap .footer-row.middle-row .contact-details-grid > div:first-child {
                border-right: none; } }
            @media (max-width: 959px) {
              footer .footer-main .wrap .footer-row.middle-row .contact-details-grid > div:first-child {
                border-bottom: none; } }
            @media (min-width: 960px) {
              footer .footer-main .wrap .footer-row.middle-row .contact-details-grid > div:last-child {
                border-left: none; } }
            @media (max-width: 959px) {
              footer .footer-main .wrap .footer-row.middle-row .contact-details-grid > div:last-child {
                display: none; } }
          footer .footer-main .wrap .footer-row.middle-row .contact-details-grid .address {
            display: block;
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 16px;
            line-height: calc(32/16);
            color: #6E675B;
            color: #ffffff;
            line-height: 1.45em; }
            footer .footer-main .wrap .footer-row.middle-row .contact-details-grid .address p {
              margin: 0; }
          footer .footer-main .wrap .footer-row.middle-row .contact-details-grid .contact-details a {
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 16px;
            line-height: calc(32/16);
            color: #6E675B;
            color: #ffffff;
            display: block;
            line-height: 1.45em; }
          footer .footer-main .wrap .footer-row.middle-row .contact-details-grid .registrations span {
            display: block;
            font-family: 'Roboto', sans-serif;
            font-size: 11px;
            line-height: calc(15/11);
            font-weight: 300;
            color: #F2F2F2;
            line-height: calc(18/13);
            size: 13px;
            color: #ffffff; }
      @media (max-width: 1079px) {
        footer .footer-main .wrap .footer-row.bottom-row {
          justify-content: flex-start; } }
      @media (max-width: 549px) {
        footer .footer-main .wrap .footer-row.bottom-row {
          justify-content: center; } }
      footer .footer-main .wrap .footer-row.bottom-row .social-links {
        display: flex;
        flex-direction: row; }
        footer .footer-main .wrap .footer-row.bottom-row .social-links .social {
          margin-right: 15px;
          width: 26px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center; }
          footer .footer-main .wrap .footer-row.bottom-row .social-links .social svg {
            width: 100%; }
          footer .footer-main .wrap .footer-row.bottom-row .social-links .social:last-child {
            margin-right: 0; }
      footer .footer-main .wrap .footer-row.bottom-row .disclaimers {
        max-width: 850px; }
        @media (max-width: 1079px) {
          footer .footer-main .wrap .footer-row.bottom-row .disclaimers {
            max-width: 100%;
            min-width: 100%;
            padding-top: 20px; } }
        footer .footer-main .wrap .footer-row.bottom-row .disclaimers .disclaimer {
          font-family: 'Roboto', sans-serif;
          font-size: 11px;
          line-height: calc(15/11);
          font-weight: 300;
          color: #F2F2F2;
          color: #ffffff;
          text-align: right;
          margin-bottom: 40px; }
          @media (max-width: 1079px) {
            footer .footer-main .wrap .footer-row.bottom-row .disclaimers .disclaimer {
              display: none; } }
        footer .footer-main .wrap .footer-row.bottom-row .disclaimers .copyright {
          text-align: right;
          font-family: 'Roboto', sans-serif;
          font-size: 11px;
          line-height: calc(15/11);
          font-weight: 300;
          color: #F2F2F2;
          color: #ffffff; }
          @media (max-width: 1079px) {
            footer .footer-main .wrap .footer-row.bottom-row .disclaimers .copyright {
              text-align: left; } }
          @media (max-width: 549px) {
            footer .footer-main .wrap .footer-row.bottom-row .disclaimers .copyright {
              text-align: center; } }
          footer .footer-main .wrap .footer-row.bottom-row .disclaimers .copyright span:after {
            content: '/';
            margin: 0 5px; }
          footer .footer-main .wrap .footer-row.bottom-row .disclaimers .copyright span a {
            margin-left: 3.5px;
            color: #ffffff; }
            footer .footer-main .wrap .footer-row.bottom-row .disclaimers .copyright span a:hover {
              text-decoration: underline; }
          footer .footer-main .wrap .footer-row.bottom-row .disclaimers .copyright span:last-child:after {
            display: none; }
