/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.search-form-block {
  background: #ffffff;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1); }
  .search-form-block.active {
    max-height: 300px;
    -webkit-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease; }
  .search-form-block .wrap {
    display: flex;
    align-items: center; }
  .search-form-block .search-form {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .search-form-block .search-form input {
      margin: 0;
      padding: 20px 0; }
    .search-form-block .search-form button {
      margin: 0 !important;
      width: 22px;
      padding: 0;
      border: 0;
      background: none; }
      .search-form-block .search-form button svg {
        opacity: 0;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s; }
      .search-form-block .search-form button.active svg {
        opacity: 1; }
  .search-form-block .hamburger {
    width: 24px;
    height: 24px;
    position: relative;
    outline: none;
    cursor: pointer;
    border: 0;
    background: none;
    padding: 0;
    margin-left: 25px; }
    .search-form-block .hamburger:before, .search-form-block .hamburger:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #817B71;
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s; }
    .search-form-block .hamburger:after {
      margin: 8px 0 0; }
    .search-form-block .hamburger.active:before {
      position: absolute;
      top: 11px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .search-form-block .hamburger.active:after {
      position: absolute;
      bottom: 11px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
