/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.page-header {
  background-color: #D3EDEF; }
  .page-header.plain .wrap .columns .right {
    padding-bottom: 100px; }
    .page-header.plain .wrap .columns .right .text {
      margin-bottom: 30px; }
  .page-header.graphic .wrap .columns .left {
    overflow: hidden;
    padding-top: 0; }
    @media (max-width: 959px) {
      .page-header.graphic .wrap .columns .left {
        display: none; } }
    .page-header.graphic .wrap .columns .left .accent {
      position: relative; }
      .page-header.graphic .wrap .columns .left .accent .page-header-image .gatsby-image {
        right: -33%;
        width: 90%;
        height: auto;
        opacity: 0.2;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-transition: right 1s;
        transition: right 1s; }
      .page-header.graphic .wrap .columns .left .accent .page-header-image.visible .gatsby-image {
        right: -25%; }
      .page-header.graphic .wrap .columns .left .accent .page-header-video .page-header-video-embed {
        right: -33%;
        width: 90%;
        height: auto;
        opacity: 0.2;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-transition: right 1s;
        transition: right 1s; }
      .page-header.graphic .wrap .columns .left .accent .page-header-video.visible .page-header-video-embed {
        right: -25%; }
  .page-header.graphic .wrap .columns .right {
    padding-bottom: 0; }
    .page-header.graphic .wrap .columns .right .text {
      margin-bottom: 70px; }
  @media (max-width: 749px) {
    .page-header.video .wrap .subtitle {
      padding-bottom: 30px; } }
  @media (max-width: 959px) {
    .page-header.video .wrap .columns {
      flex-direction: column !important; } }
  .page-header.video .wrap .columns .left {
    padding-top: 50px; }
    @media (max-width: 959px) {
      .page-header.video .wrap .columns .left {
        padding-top: 30px;
        padding-left: 60px; } }
    @media (max-width: 749px) {
      .page-header.video .wrap .columns .left {
        padding-left: 0;
        padding-top: 0; } }
    .page-header.video .wrap .columns .left .accent {
      position: relative;
      margin: 0;
      height: 300px; }
      @media (max-width: 749px) {
        .page-header.video .wrap .columns .left .accent {
          height: 240px; } }
      @media (max-width: 399px) {
        .page-header.video .wrap .columns .left .accent {
          height: 220px; } }
      .page-header.video .wrap .columns .left .accent .page-header-video {
        height: 100%; }
        .page-header.video .wrap .columns .left .accent .page-header-video iframe {
          right: -33%;
          width: 110%;
          height: 100%;
          -webkit-transition: right 1s;
          transition: right 1s; }
          @media (max-width: 749px) {
            .page-header.video .wrap .columns .left .accent .page-header-video iframe {
              width: 100%; } }
  .page-header.video .wrap .columns .right {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 110px; }
    @media (max-width: 959px) {
      .page-header.video .wrap .columns .right {
        padding-bottom: 0px;
        padding-left: 60px; } }
    @media (max-width: 749px) {
      .page-header.video .wrap .columns .right {
        padding-left: 20px; } }
    .page-header.video .wrap .columns .right .text {
      margin-bottom: 70px; }
  .page-header.quote .wrap .columns .right .text {
    margin-left: 70px; }
    @media (max-width: 959px) {
      .page-header.quote .wrap .columns .right .text {
        margin-left: 0; } }
  .page-header .top {
    position: relative; }
    .page-header .top .breadcrumbs {
      padding: 20px 0;
      border-bottom: 2px solid #ffffff;
      font-family: 'Roboto', sans-serif;
      font-size: 11px;
      line-height: calc(15/11);
      font-weight: 400;
      color: #6E675B;
      font-size: 12px;
      letter-spacing: 1px; }
      @media (max-width: 959px) {
        .page-header .top .breadcrumbs:after {
          position: absolute;
          content: '';
          width: 20px;
          height: 100%;
          right: 0;
          top: 0;
          border-left: 2px solid #ffffff; } }
      @media (max-width: 959px) {
        .page-header .top .breadcrumbs .wrap {
          display: none; } }
      .page-header .top .breadcrumbs .wrap .crumb {
        font-family: 'Roboto', sans-serif;
        font-size: 11px;
        line-height: calc(15/11);
        font-weight: 400;
        color: #6E675B; }
        .page-header .top .breadcrumbs .wrap .crumb:last-child {
          font-weight: 700; }
    .page-header .top .title {
      padding: 35px 0;
      box-sizing: border-box;
      border-bottom: 2px solid #ffffff; }
      @media (max-width: 959px) {
        .page-header .top .title:after {
          position: absolute;
          content: '';
          width: 20px;
          height: 100%;
          right: 0;
          top: 0;
          border-left: 2px solid #ffffff; } }
      .page-header .top .title h1 {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E; }
        @media (max-width: 1339px) {
          .page-header .top .title h1 {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .page-header .top .title h1 {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .page-header .top .title h1 {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .page-header .top .title h1 {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .page-header .top .title h1 {
            font-size: 23px; } }
    .page-header .top:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;
      height: 100%;
      box-sizing: border-box;
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      background-color: #D3EDEF; }
      @media (max-width: 959px) {
        .page-header .top:after {
          display: none; } }
  .page-header .wrap .subtitle {
    width: 490px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    box-sizing: border-box;
    padding: 30px 60px 0; }
    @media (max-width: 1079px) {
      .page-header .wrap .subtitle {
        width: 400px; } }
    @media (max-width: 959px) {
      .page-header .wrap .subtitle {
        width: 100%;
        border-right: none; } }
    @media (max-width: 549px) {
      .page-header .wrap .subtitle {
        padding: 30px 20px 0; } }
    .page-header .wrap .subtitle p {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      margin-bottom: 0; }
      @media (max-width: 549px) {
        .page-header .wrap .subtitle p {
          font-size: 14px; } }
  .page-header .wrap .columns {
    display: flex;
    flex-direction: row; }
    @media (max-width: 959px) {
      .page-header .wrap .columns {
        flex-direction: column-reverse !important; } }
    .page-header .wrap .columns .left {
      position: relative;
      width: 490px;
      padding: 360px 60px 30px;
      box-sizing: border-box;
      border-left: 2px solid #ffffff;
      border-right: 2px solid #ffffff; }
      @media (max-width: 1079px) {
        .page-header .wrap .columns .left {
          width: 400px; } }
      @media (max-width: 959px) {
        .page-header .wrap .columns .left {
          border-right: none;
          padding: 0;
          width: auto; } }
      @media (max-width: 549px) {
        .page-header .wrap .columns .left {
          border: none; } }
      .page-header .wrap .columns .left .accent {
        position: relative;
        display: block;
        width: 120%;
        height: 400px;
        margin-bottom: 130px;
        margin-left: 20px; }
        @media (max-width: 1079px) {
          .page-header .wrap .columns .left .accent {
            width: 152%;
            margin-left: 0; } }
        @media (max-width: 959px) {
          .page-header .wrap .columns .left .accent {
            width: auto;
            max-width: 480px;
            margin: 0 auto 80px; } }
        @media (max-width: 749px) {
          .page-header .wrap .columns .left .accent {
            max-width: 380px;
            height: 360px; } }
        @media (max-width: 549px) {
          .page-header .wrap .columns .left .accent {
            max-width: none;
            width: calc(100% + 40px);
            left: -20px;
            margin-bottom: 0;
            height: auto; } }
        .page-header .wrap .columns .left .accent .quote {
          left: -20px;
          opacity: 0;
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 32px;
          line-height: calc(32/32);
          color: #6E675B;
          color: #ffffff;
          line-height: 48px;
          background-color: #004F6E;
          padding: 20px;
          width: 100%;
          height: 100%;
          position: absolute;
          -webkit-transition: left 1s ease, opacity .8s ease;
          transition: left 1s ease, opacity .8s ease; }
          @media (max-width: 1079px) {
            .page-header .wrap .columns .left .accent .quote {
              font-size: 28px; } }
          @media (max-width: 549px) {
            .page-header .wrap .columns .left .accent .quote {
              font-size: 23px; } }
          .page-header .wrap .columns .left .accent .quote.visible {
            left: 0;
            opacity: 1; }
          @media (max-width: 1079px) {
            .page-header .wrap .columns .left .accent .quote {
              font-size: 30px;
              line-height: 40px; } }
          @media (max-width: 959px) {
            .page-header .wrap .columns .left .accent .quote {
              position: relative; } }
          @media (max-width: 749px) {
            .page-header .wrap .columns .left .accent .quote {
              font-family: 'SignaSerifPro', serif;
              font-weight: 600;
              font-size: 23px;
              line-height: calc(32/23);
              color: #6E675B;
              font-size: 24px;
              color: #ffffff; } }
  @media (max-width: 749px) and (max-width: 1079px) {
    .page-header .wrap .columns .left .accent .quote {
      font-size: 21px; } }
          .page-header .wrap .columns .left .accent .quote .inner-square {
            position: relative;
            padding: 20px;
            width: 100%;
            height: 100%;
            border: 3px solid #ffffff;
            display: flex;
            align-items: flex-end; }
            @media (max-width: 549px) {
              .page-header .wrap .columns .left .accent .quote .inner-square {
                padding-right: 80px; } }
    .page-header .wrap .columns .right {
      flex: 3 1;
      padding: 80px 60px 120px; }
      @media (max-width: 959px) {
        .page-header .wrap .columns .right {
          padding: 50px 60px;
          border-left: 2px solid #ffffff; } }
      @media (max-width: 549px) {
        .page-header .wrap .columns .right {
          padding: 50px 20px; } }
      .page-header .wrap .columns .right .headline {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        margin-bottom: 30px;
        margin-right: 50px; }
        @media (max-width: 1339px) {
          .page-header .wrap .columns .right .headline {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .page-header .wrap .columns .right .headline {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .page-header .wrap .columns .right .headline {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .page-header .wrap .columns .right .headline {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .page-header .wrap .columns .right .headline {
            font-size: 23px; } }
        @media (max-width: 1079px) {
          .page-header .wrap .columns .right .headline {
            margin-right: 0; } }
      .page-header .wrap .columns .right .text {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B; }
        @media (max-width: 549px) {
          .page-header .wrap .columns .right .text {
            font-size: 14px; } }
        @media (max-width: 959px) {
          .page-header .wrap .columns .right .text .styled-button {
            margin-bottom: 30px; } }
