/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.article-intro-block .page-header-short .subtitle {
  padding-top: 30px;
  padding-bottom: 80px; }
  .article-intro-block .page-header-short .subtitle .back-link {
    position: relative;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: calc(24/16);
    font-weight: 400;
    color: #6E675B;
    align-items: center;
    margin-left: 70px; }
    @media (max-width: 549px) {
      .article-intro-block .page-header-short .subtitle .back-link {
        font-size: 14px; } }
    .article-intro-block .page-header-short .subtitle .back-link:before {
      position: absolute;
      left: -60px;
      content: '';
      width: 40px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93TGVmdHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgMTEuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTcuNTAwMDAwLCAtMTEuMDAwMDAwKSAiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
      background-position: center;
      background-repeat: no-repeat; }

.article-intro-block .intro {
  position: relative;
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #D3EDEF), color-stop(50%, #ffffff));
  background-image: linear-gradient(270deg, #D3EDEF 50%, #ffffff 50%); }
  @media (max-width: 1079px) {
    .article-intro-block .intro {
      display: flex;
      flex-direction: column-reverse; } }
  .article-intro-block .intro .social-links {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
    .article-intro-block .intro .social-links.stick {
      position: fixed; }
    .article-intro-block .intro .social-links.stuck {
      top: auto;
      bottom: 0; }
    @media (max-width: 1079px) {
      .article-intro-block .intro .social-links {
        position: static !important;
        display: flex;
        background: #ffffff; } }
    .article-intro-block .intro .social-links .link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: #EEECEA;
      font-size: 0;
      border-bottom: 1px solid #ffffff;
      box-sizing: border-box; }
      @media (max-width: 1079px) {
        .article-intro-block .intro .social-links .link {
          border-bottom: none;
          border-right: 1px solid #ffffff;
          width: 100%;
          flex: 1 1; } }
      @media (max-width: 399px) {
        .article-intro-block .intro .social-links .link {
          height: 60px; } }
      .article-intro-block .intro .social-links .link a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .article-intro-block .intro .social-links .link a:not(.social-link) svg {
          width: 20px;
          height: 20px;
          fill: #817B71;
          -webkit-transition: fill .3s ease;
          transition: fill .3s ease; }
        .article-intro-block .intro .social-links .link a:not(.social-link):hover svg {
          fill: #004F6E; }
        @media (max-width: 399px) {
          .article-intro-block .intro .social-links .link a svg {
            width: 15px;
            height: 15px; } }
  .article-intro-block .intro .container {
    display: flex;
    flex-direction: row; }
    @media (max-width: 1079px) {
      .article-intro-block .intro .container {
        flex-direction: column-reverse; } }
    .article-intro-block .intro .container .left {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 120px;
      padding-top: 40px;
      padding-left: 100px;
      background-color: #ffffff; }
      @media (max-width: 1079px) {
        .article-intro-block .intro .container .left {
          padding: 50px 0 30px; } }
      @media (max-width: 399px) {
        .article-intro-block .intro .container .left {
          padding: 30px 0 10px; } }
      .article-intro-block .intro .container .left .text-container {
        padding-right: 70px;
        width: 100%; }
        @media (max-width: 1199px) {
          .article-intro-block .intro .container .left .text-container {
            padding-right: 30px; } }
        @media (max-width: 1079px) {
          .article-intro-block .intro .container .left .text-container {
            max-width: none;
            padding-right: 0;
            margin: auto; } }
        .article-intro-block .intro .container .left .text-container .post-title {
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 36px;
          line-height: calc(48/36);
          color: #004F6E;
          margin-bottom: 25px; }
          @media (max-width: 1339px) {
            .article-intro-block .intro .container .left .text-container .post-title {
              font-size: 32px; } }
          @media (max-width: 1079px) {
            .article-intro-block .intro .container .left .text-container .post-title {
              font-size: 30px; } }
          @media (max-width: 959px) {
            .article-intro-block .intro .container .left .text-container .post-title {
              font-size: 28px; } }
          @media (max-width: 749px) {
            .article-intro-block .intro .container .left .text-container .post-title {
              font-size: 26px; } }
          @media (max-width: 549px) {
            .article-intro-block .intro .container .left .text-container .post-title {
              font-size: 23px; } }
        .article-intro-block .intro .container .left .text-container .post-image {
          position: relative;
          width: 100%;
          height: 400px;
          margin: 30px 0 40px; }
          @media (min-width: 1600px) {
            .article-intro-block .intro .container .left .text-container .post-image {
              height: 600px; } }
          @media (min-width: 1340px) {
            .article-intro-block .intro .container .left .text-container .post-image {
              height: 500px; } }
          @media (max-width: 749px) {
            .article-intro-block .intro .container .left .text-container .post-image {
              height: 350px; } }
          @media (max-width: 549px) {
            .article-intro-block .intro .container .left .text-container .post-image {
              height: 280px; } }
        .article-intro-block .intro .container .left .text-container .post-subtitle {
          font-family: 'Georgia', serif;
          font-style: italic;
          font-size: 22px;
          line-height: calc(32/22);
          color: #817B71;
          margin-bottom: 30px; }
          @media (max-width: 549px) {
            .article-intro-block .intro .container .left .text-container .post-subtitle {
              font-size: 18px; } }
        .article-intro-block .intro .container .left .text-container .body-text {
          padding-right: 200px; }
          @media (max-width: 1079px) {
            .article-intro-block .intro .container .left .text-container .body-text {
              padding-right: 50px; } }
          @media (max-width: 749px) {
            .article-intro-block .intro .container .left .text-container .body-text {
              padding-right: 0; } }
          .article-intro-block .intro .container .left .text-container .body-text .text {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: calc(24/16);
            font-weight: 400;
            color: #6E675B;
            padding-bottom: 20px; }
            @media (max-width: 549px) {
              .article-intro-block .intro .container .left .text-container .body-text .text {
                font-size: 14px; } }
            .article-intro-block .intro .container .left .text-container .body-text .text figure {
              width: auto !important;
              margin-bottom: 20px;
              font-style: italic;
              font-size: 14px; }
              @media (max-width: 549px) {
                .article-intro-block .intro .container .left .text-container .body-text .text figure {
                  font-size: 12px; } }
            .article-intro-block .intro .container .left .text-container .body-text .text ul, .article-intro-block .intro .container .left .text-container .body-text .text ol {
              list-style: disc;
              padding: 0 0 0 25px;
              margin-bottom: 20px; }
              .article-intro-block .intro .container .left .text-container .body-text .text ul li, .article-intro-block .intro .container .left .text-container .body-text .text ol li {
                margin-bottom: 12px; }
            .article-intro-block .intro .container .left .text-container .body-text .text ol {
              list-style: decimal; }
        .article-intro-block .intro .container .left .text-container .video-button {
          cursor: pointer; }
          @media (max-width: 1079px) {
            .article-intro-block .intro .container .left .text-container .video-button {
              margin-left: 0; } }
    .article-intro-block .intro .container .right {
      width: 50%;
      position: relative;
      background-color: #D3EDEF;
      top: -100px; }
      @media (max-width: 1079px) {
        .article-intro-block .intro .container .right {
          top: 0;
          width: 100%; } }
      .article-intro-block .intro .container .right .image {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 800px; }
        @media (max-width: 1079px) {
          .article-intro-block .intro .container .right .image {
            height: 369px; } }
        @media (max-width: 399px) {
          .article-intro-block .intro .container .right .image {
            height: 235px; } }

.author-box {
  display: flex;
  color: #6E675B; }
  .author-box .author-image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 20px; }
  .author-box .author-name {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: bold; }
  .author-box .author-socials {
    margin-left: 5px;
    display: flex; }
    .author-box .author-socials .social-link {
      display: flex;
      align-items: center;
      margin: 0 6px; }
  .author-box .author-posted-on {
    padding: 1px 0 0;
    font-size: 14px; }
  .author-box .category-link {
    display: inline-block;
    margin: 0; }
    .author-box .category-link a {
      text-decoration: underline;
      color: #6E675B; }
  .author-box .author-bio {
    font-style: italic;
    border-left: #004F6E 2px solid;
    padding: 0 0 0 10px;
    margin: 10px 0 0;
    max-width: 460px; }
