/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.home-slider {
  background-color: #D3EDEF;
  background: -webkit-gradient(linear, left top, left bottom, from(#D3EDEF), color-stop(80%, #D3EDEF), color-stop(80%, #D3EDEF), color-stop(80%, #ffffff), color-stop(80%, #ffffff), to(#ffffff));
  background: linear-gradient(to bottom, #D3EDEF 0%, #D3EDEF 80%, #D3EDEF 80%, #ffffff 80%, #ffffff 80%, #ffffff 100%); }
  @media (max-width: 749px) {
    .home-slider {
      background: -webkit-gradient(linear, left top, left bottom, from(#D3EDEF), color-stop(70%, #D3EDEF), color-stop(70%, #D3EDEF), color-stop(70%, #ffffff), color-stop(70%, #ffffff), to(#ffffff));
      background: linear-gradient(to bottom, #D3EDEF 0%, #D3EDEF 70%, #D3EDEF 70%, #ffffff 70%, #ffffff 70%, #ffffff 100%); } }
  .home-slider .home-slider-slides {
    padding: 0; }
    .home-slider .home-slider-slides .slide {
      margin: 70px 0 0;
      border-top: 2px solid #ffffff;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      position: relative; }
      @media (max-width: 959px) {
        .home-slider .home-slider-slides .slide {
          margin-top: 40px; } }
      @media (max-width: 749px) {
        .home-slider .home-slider-slides .slide {
          border-top-width: 0; } }
      @media (max-width: 549px) {
        .home-slider .home-slider-slides .slide {
          margin-top: 20px; } }
      .home-slider .home-slider-slides .slide:before {
        content: '';
        display: block;
        width: 100vw;
        height: 2px;
        background: #ffffff;
        position: absolute;
        left: 0;
        bottom: 220px; }
        @media (max-width: 959px) {
          .home-slider .home-slider-slides .slide:before {
            bottom: 170px; } }
        @media (max-width: 749px) {
          .home-slider .home-slider-slides .slide:before {
            display: none; } }
      .home-slider .home-slider-slides .slide .wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-right: 2px solid #ffffff;
        padding: 40px 20px 0; }
        @media (max-width: 1339px) {
          .home-slider .home-slider-slides .slide .wrap {
            border-right: 0; } }
        @media (max-width: 959px) {
          .home-slider .home-slider-slides .slide .wrap {
            padding-top: 0;
            padding-bottom: 100px; } }
        @media (max-width: 749px) {
          .home-slider .home-slider-slides .slide .wrap {
            flex-direction: column-reverse;
            padding-bottom: 275px; } }
        @media (max-width: 549px) {
          .home-slider .home-slider-slides .slide .wrap {
            padding-bottom: 260px; } }
        .home-slider .home-slider-slides .slide .wrap .image-col {
          display: block;
          position: relative;
          min-width: 548px;
          min-height: 620px;
          z-index: 2;
          flex: 1 1; }
          @media (max-width: 959px) {
            .home-slider .home-slider-slides .slide .wrap .image-col {
              min-width: 380px;
              min-height: 430px; } }
          @media (max-width: 749px) {
            .home-slider .home-slider-slides .slide .wrap .image-col {
              min-width: 215px;
              min-height: 250px;
              position: absolute;
              bottom: 0px;
              left: 30px; } }
          @media (max-width: 549px) {
            .home-slider .home-slider-slides .slide .wrap .image-col {
              left: 20px; } }
          @media (max-width: 399px) {
            .home-slider .home-slider-slides .slide .wrap .image-col {
              left: 0; } }
        .home-slider .home-slider-slides .slide .wrap .text-col {
          margin-top: 30px;
          margin-left: -80px;
          margin-right: 100px;
          max-width: 710px;
          flex: 2 1; }
          @media (max-width: 1339px) {
            .home-slider .home-slider-slides .slide .wrap .text-col {
              margin-right: 0; } }
          @media (max-width: 749px) {
            .home-slider .home-slider-slides .slide .wrap .text-col {
              min-width: 100%;
              padding: 0 40px;
              margin-left: 0; } }
          @media (max-width: 549px) {
            .home-slider .home-slider-slides .slide .wrap .text-col {
              padding: 0 20px; } }
          @media (max-width: 399px) {
            .home-slider .home-slider-slides .slide .wrap .text-col {
              padding: 0; } }
          .home-slider .home-slider-slides .slide .wrap .text-col .title {
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 46px;
            line-height: calc(56/46);
            color: #004F6E;
            margin-bottom: 20px; }
            @media (max-width: 1339px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .title {
                font-size: 38px; } }
            @media (max-width: 1079px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .title {
                font-size: 34px; } }
            @media (max-width: 959px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .title {
                font-size: 28px; } }
            @media (max-width: 749px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .title {
                font-size: 26px; } }
            @media (max-width: 549px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .title {
                font-size: 22px; } }
          .home-slider .home-slider-slides .slide .wrap .text-col .subtitle {
            font-family: 'Roboto', sans-serif;
            font-size: 22px;
            line-height: calc(24/16);
            font-weight: 400;
            color: #004F6E;
            margin-bottom: 40px; }
            @media (max-width: 1079px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .subtitle {
                font-size: 20px; } }
            @media (max-width: 959px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .subtitle {
                font-size: 18px; } }
            @media (max-width: 749px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .subtitle {
                font-size: 16px; } }
            @media (max-width: 749px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .subtitle {
                margin-bottom: 25px; } }
            @media (max-width: 1079px) {
              .home-slider .home-slider-slides .slide .wrap .text-col .subtitle br {
                content: " ";
                display: none; } }
          @media (max-width: 549px) {
            .home-slider .home-slider-slides .slide .wrap .text-col .styled-button {
              width: 100%;
              text-align: center; } }
  @media (max-width: 549px) {
    .home-slider .tns-nav button {
      border-bottom-color: #F2F2F2; } }
  @media (max-width: 549px) {
    .home-slider .tns-nav button.tns-nav-active {
      background-color: #F2F2F2; } }
