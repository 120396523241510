/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.call-to-action-group {
  padding-bottom: 120px; }
  @media (max-width: 959px) {
    .call-to-action-group {
      padding-bottom: 0; } }
  .call-to-action-group.white {
    background: #ffffff; }
  .call-to-action-group.tertiaryWhite {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #D3EDEF), color-stop(50%, #ffffff));
    background-image: linear-gradient(180deg, #D3EDEF 50%, #ffffff 50%); }
  .call-to-action-group.whiteTertiary {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffffff), color-stop(50%, #D3EDEF));
    background-image: linear-gradient(180deg, #ffffff 50%, #D3EDEF 50%); }
  .call-to-action-group.offWhiteWhite {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #EEECEA), color-stop(50%, #ffffff));
    background-image: linear-gradient(180deg, #EEECEA 50%, #ffffff 50%); }
  .call-to-action-group.whiteOffWhite {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffffff), color-stop(50%, #EEECEA));
    background-image: linear-gradient(180deg, #ffffff 50%, #EEECEA 50%); }
  .call-to-action-group.primaryWhite {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #004F6E), color-stop(50%, #ffffff));
    background-image: linear-gradient(180deg, #004F6E 50%, #ffffff 50%); }
  .call-to-action-group.whitePrimary {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffffff), color-stop(50%, #004F6E));
    background-image: linear-gradient(180deg, #ffffff 50%, #004F6E 50%); }
  @media (max-width: 959px) {
    .call-to-action-group .wrap {
      padding: 0; } }
  .call-to-action-group .wrap .call-to-actions {
    display: flex;
    flex-direction: row; }
    @media (max-width: 1199px) {
      .call-to-action-group .wrap .call-to-actions {
        flex-wrap: wrap; } }
    @media (max-width: 959px) {
      .call-to-action-group .wrap .call-to-actions {
        flex-direction: column; } }
    .call-to-action-group .wrap .call-to-actions .call-to-action {
      padding: 40px 20px 20px;
      text-align: left; }
      @media (max-width: 1199px) {
        .call-to-action-group .wrap .call-to-actions .call-to-action {
          width: 50%; } }
      @media (max-width: 959px) {
        .call-to-action-group .wrap .call-to-actions .call-to-action {
          width: 100%; } }
      @media (max-width: 549px) {
        .call-to-action-group .wrap .call-to-actions .call-to-action {
          padding: 40px 20px 60px; } }
      .call-to-action-group .wrap .call-to-actions .call-to-action.primary .inner-container .headline, .call-to-action-group .wrap .call-to-actions .call-to-action.primary .inner-container .body-text {
        color: #ffffff; }
      .call-to-action-group .wrap .call-to-actions .call-to-action.white .inner-container {
        border: 1px solid #D9D9D9; }
      .call-to-action-group .wrap .call-to-actions .call-to-action .title {
        padding-left: 20px;
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71;
        margin-bottom: 15px; }
      .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container {
        padding: 50px 20px;
        border: 1px solid #ffffff; }
        @media (max-width: 959px) {
          .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container {
            padding: 30px 20px; } }
        .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container .headline {
          display: block;
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 32px;
          line-height: calc(32/32);
          color: #004F6E;
          margin-bottom: 40px; }
          @media (max-width: 1079px) {
            .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container .headline {
              font-size: 28px; } }
          @media (max-width: 549px) {
            .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container .headline {
              font-size: 23px; } }
          @media (max-width: 549px) {
            .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container .headline {
              font-size: 23px; } }
        .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container .body-text {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: calc(24/16);
          font-weight: 400;
          color: #6E675B;
          margin-bottom: 30px; }
          @media (max-width: 549px) {
            .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container .body-text {
              font-size: 14px; } }
        @media (max-width: 549px) {
          .call-to-action-group .wrap .call-to-actions .call-to-action .inner-container .styled-button {
            width: 100%;
            text-align: center; } }
