/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.grid-block {
  overflow: hidden; }
  .grid-block.white {
    background-color: #ffffff; }
    .grid-block.white .inner-container {
      border-color: #F2F2F2; }
  .grid-block.off-white {
    background-color: #F2F2F2; }
  .grid-block.primary {
    background-color: #004F6E; }
    .grid-block.primary .outer-container .title {
      color: #3591B6; }
    .grid-block.primary .inner-container .headline {
      color: #ffffff; }
    .grid-block.primary .inner-container .body-text {
      color: #ffffff; }
  .grid-block.tertiary {
    background-color: #D3EDEF; }
  .grid-block.photo-left .container {
    flex-direction: row; }
  .grid-block.photo-left .text-col {
    align-items: flex-start; }
    @media (max-width: 1079px) {
      .grid-block.photo-left .text-col {
        align-items: center; } }
  .grid-block.photo-left .image-col {
    right: -20px;
    opacity: 0.8;
    -webkit-transition: right 1s, opacity 0.8s;
    transition: right 1s, opacity 0.8s; }
  .grid-block.photo-left .outer-container {
    padding-right: 20px; }
    @media (max-width: 549px) {
      .grid-block.photo-left .outer-container {
        padding-right: 0; } }
  @media (min-width: 1080px) {
    .grid-block.photo-left .inner-container {
      border-left: none; } }
  .grid-block.photo-left .visible .image-col {
    right: 0;
    opacity: 1; }
  .grid-block.photo-right .container {
    flex-direction: row-reverse; }
  .grid-block.photo-right .text-col {
    align-items: flex-end; }
    @media (max-width: 1079px) {
      .grid-block.photo-right .text-col {
        align-items: center; } }
  .grid-block.photo-right .image-col {
    right: -20px;
    opacity: 0.8;
    -webkit-transition: right 1s, opacity 0.8s;
    transition: right 1s, opacity 0.8s; }
  .grid-block.photo-right .outer-container {
    padding-left: 20px; }
    @media (max-width: 1079px) {
      .grid-block.photo-right .outer-container {
        padding-left: 0; } }
  @media (min-width: 1080px) {
    .grid-block.photo-right .inner-container {
      border-right: none; } }
  .grid-block.photo-right .visible .image-col {
    right: 0;
    opacity: 1; }
  .grid-block .container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 150px; }
    @media (max-width: 1079px) {
      .grid-block .container {
        padding-bottom: 40px; } }
  .grid-block .image-col {
    flex: 1 1;
    position: relative;
    margin-top: -50px; }
    @media (max-width: 1079px) {
      .grid-block .image-col {
        min-width: 100%;
        height: 369px;
        margin-top: 0; } }
    @media (max-width: 399px) {
      .grid-block .image-col {
        height: 290px; } }
  .grid-block .text-col {
    flex: 1 1;
    display: flex;
    flex-direction: column; }
  .grid-block .outer-container {
    max-width: 725px;
    padding-top: 70px;
    padding-bottom: 70px; }
    @media (max-width: 1079px) {
      .grid-block .outer-container {
        padding-top: 25px;
        padding-bottom: 0; } }
    .grid-block .outer-container .title {
      display: block;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      margin-left: 60px;
      margin-bottom: 20px; }
      @media (max-width: 1079px) {
        .grid-block .outer-container .title {
          margin: 0 0 20px;
          padding: 0 20px;
          text-align: center; } }
  .grid-block .inner-container {
    border: 2px solid #ffffff;
    padding: 50px 140px 60px 60px; }
    @media (max-width: 1339px) {
      .grid-block .inner-container {
        padding: 50px 55px 60px 40px; } }
    @media (max-width: 1079px) {
      .grid-block .inner-container {
        margin: 0 15px;
        border-width: 3px; } }
    @media (max-width: 549px) {
      .grid-block .inner-container {
        padding: 40px 30px 30px; } }
    .grid-block .inner-container .headline {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 36px;
      line-height: calc(48/36);
      color: #004F6E;
      margin-bottom: 30px; }
      @media (max-width: 1339px) {
        .grid-block .inner-container .headline {
          font-size: 32px; } }
      @media (max-width: 1079px) {
        .grid-block .inner-container .headline {
          font-size: 30px; } }
      @media (max-width: 959px) {
        .grid-block .inner-container .headline {
          font-size: 28px; } }
      @media (max-width: 749px) {
        .grid-block .inner-container .headline {
          font-size: 26px; } }
      @media (max-width: 549px) {
        .grid-block .inner-container .headline {
          font-size: 23px; } }
    .grid-block .inner-container .body-text {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      margin-bottom: 30px; }
      @media (max-width: 549px) {
        .grid-block .inner-container .body-text {
          font-size: 14px; } }
