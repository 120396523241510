/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.enquiry-button {
  position: fixed;
  right: 0;
  bottom: calc(146px + 15vh);
  border: 1px solid #ffffff;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  padding: 10px 25px;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: right 0.4s ease;
  transition: right 0.4s ease; }
  .enquiry-button.hidden {
    right: -80px; }
  .enquiry-button svg {
    display: none;
    width: 22px;
    height: 22px; }
  @media (max-width: 549px) {
    .enquiry-button {
      bottom: 20px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      right: 20px;
      padding: 15px;
      border-bottom: 1px solid #ffffff;
      border-radius: 50%; }
      .enquiry-button span {
        display: none; }
      .enquiry-button svg {
        display: block; } }
  .enquiry-button:before {
    -webkit-transition: none;
    transition: none; }
