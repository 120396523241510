/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.tns-outer {
  position: relative; }

.tns-nav {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: auto;
  bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 1240px;
  padding: 0px 40px;
  margin: 0 auto;
  z-index: 1; }
  @media (max-width: 959px) {
    .tns-nav {
      bottom: 55px; } }
  @media (max-width: 749px) {
    .tns-nav {
      bottom: 105px; } }
  @media (max-width: 549px) {
    .tns-nav {
      bottom: 115px; } }
  @media (max-width: 399px) {
    .tns-nav {
      padding: 0px 20px; } }
  .tns-nav button {
    flex: 1 1;
    max-width: 90px;
    height: 6px;
    background-color: transparent;
    border: none;
    border-bottom: 1px #C7C7C7 solid;
    background-color: none;
    padding: 0;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    margin: 0;
    cursor: pointer; }
    @media (max-width: 749px) {
      .tns-nav button {
        max-width: 60px; } }
    @media (max-width: 549px) {
      .tns-nav button {
        max-width: 45px; } }
    @media (max-width: 399px) {
      .tns-nav button {
        max-width: 30px; } }
    .tns-nav button.tns-nav-active {
      height: 6px;
      background-color: #C7C7C7; }
    .tns-nav button:focus, .tns-nav button:active {
      outline: none; }
