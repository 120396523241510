/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.three-column-links-list {
  padding: 120px 0; }
  @media (max-width: 1079px) {
    .three-column-links-list {
      padding: 80px 0; } }
  .three-column-links-list .wrap .group {
    display: block;
    margin-bottom: 50px; }
    @media (max-width: 1079px) {
      .three-column-links-list .wrap .group {
        margin-bottom: 25px; } }
    .three-column-links-list .wrap .group .title {
      margin-bottom: 40px;
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 23px;
      line-height: calc(32/23);
      color: #004F6E; }
      @media (max-width: 1079px) {
        .three-column-links-list .wrap .group .title {
          font-size: 21px; } }
    .three-column-links-list .wrap .group .links {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .three-column-links-list .wrap .group .links .link {
        display: flex;
        flex-direction: row;
        width: calc(100% / 3);
        padding: 0 30px;
        margin-bottom: 25px; }
        @media (max-width: 1079px) {
          .three-column-links-list .wrap .group .links .link {
            width: 50%;
            padding: 0 20px; } }
        @media (max-width: 549px) {
          .three-column-links-list .wrap .group .links .link {
            width: 100%;
            padding: 0; } }
        .three-column-links-list .wrap .group .links .link .pdf-icon {
          position: relative;
          flex-shrink: 0;
          flex-basis: 18px;
          margin-right: 10px; }
          @media (max-width: 549px) {
            .three-column-links-list .wrap .group .links .link .pdf-icon {
              flex-basis: 15px; } }
        .three-column-links-list .wrap .group .links .link a {
          display: inline-block;
          line-height: 1.4em; }
