/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
/*=============================================>>>>>
= Global Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Reset =
===============================================>>>>>*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*=============================================>>>>>
= Print Resets =
===============================================>>>>>*/
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    height: auto !important;
    width: auto !important;
    float: none !important; }
  nav {
    display: none !important; }
  a,
  a:visited {
    color: #000 !important;
    text-decoration: underline; }
  a::after {
    content: " (" attr(href) ")"; }
  abbr::after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #000;
    page-break-inside: avoid; }
  img {
    page-break-inside: avoid; }
  @page {
    margin: 0.2cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/*=============================================>>>>>
= Font Helper =
===============================================>>>>>*/
@font-face {
  font-family: 'SignaSerifPro';
  src: url(/static/SignaSerifPro-Demibold-ec508876a431371a67c6907b614510a0.woff2) format("woff2"), url(/static/SignaSerifPro-Demibold-55693d7711f46f780fac5635811852ff.woff) format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Georgia';
  src: url(/static/Georgia-Italic-527a95c49436a9c7a8673ca4121523dd.woff2) format("woff2"), url(/static/Georgia-Italic-c1bbb385eab0a85328efa1aef13f7094.woff) format("woff");
  font-weight: 400;
  font-style: italic; }

/*=============================================>>>>>
= Default typography styles =
===============================================>>>>>*/
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-size-adjust: auto;
  line-height: 1.75em;
  text-align: left;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased; }
  @media (max-width: 549px) {
    body {
      font-size: 14px; } }

h1 + h2,
h2 + h3,
h3 + h4,
h5 + h6 {
  margin-top: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'SignaSerifPro', serif;
  font-weight: 600;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 30px; }
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0; }

h1 {
  font-family: 'SignaSerifPro', serif;
  font-weight: 600;
  font-size: 46px;
  line-height: calc(56/46);
  color: #004F6E; }
  @media (max-width: 1339px) {
    h1 {
      font-size: 38px; } }
  @media (max-width: 1079px) {
    h1 {
      font-size: 34px; } }
  @media (max-width: 959px) {
    h1 {
      font-size: 28px; } }
  @media (max-width: 749px) {
    h1 {
      font-size: 26px; } }
  @media (max-width: 549px) {
    h1 {
      font-size: 22px; } }

h2 {
  font-family: 'SignaSerifPro', serif;
  font-weight: 600;
  font-size: 36px;
  line-height: calc(48/36);
  color: #004F6E; }
  @media (max-width: 1339px) {
    h2 {
      font-size: 32px; } }
  @media (max-width: 1079px) {
    h2 {
      font-size: 30px; } }
  @media (max-width: 959px) {
    h2 {
      font-size: 28px; } }
  @media (max-width: 749px) {
    h2 {
      font-size: 26px; } }
  @media (max-width: 549px) {
    h2 {
      font-size: 23px; } }

h3 {
  font-family: 'SignaSerifPro', serif;
  font-weight: 600;
  font-size: 32px;
  line-height: calc(32/32);
  color: #004F6E; }
  @media (max-width: 1079px) {
    h3 {
      font-size: 28px; } }
  @media (max-width: 549px) {
    h3 {
      font-size: 23px; } }

h4 {
  font-family: 'SignaSerifPro', serif;
  font-weight: 600;
  font-size: 23px;
  line-height: calc(32/23);
  color: #004F6E; }
  @media (max-width: 1079px) {
    h4 {
      font-size: 21px; } }

h5 {
  font-family: 'SignaSerifPro', serif;
  font-weight: 600;
  font-size: 20px;
  line-height: calc(32/20);
  color: #004F6E; }
  @media (max-width: 1079px) {
    h5 {
      font-size: 18px; } }

h6 {
  font-family: 'SignaSerifPro', serif;
  font-weight: 600;
  font-size: 20px;
  line-height: calc(32/20);
  color: #004F6E;
  font-size: 14px; }
  @media (max-width: 1079px) {
    h6 {
      font-size: 18px; } }

a {
  color: #004F6E;
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: underline; }

ul {
  margin-bottom: 20px; }

p {
  margin-top: 0;
  margin-bottom: 20px; }

strong, b {
  font-weight: 700; }

em {
  font-style: italic; }

figure {
  margin: 0;
  padding: 0; }

img {
  max-width: 100%;
  height: auto; }

hr {
  display: block;
  margin: 20px 0;
  border: 0; }

blockquote {
  margin: 0 0 20px;
  font-family: cambria, georgia, times, serif;
  font-style: italic; }
  blockquote p:last-child {
    margin-bottom: 0; }

table {
  width: 100%; }

small {
  font-size: 12px;
  color: #817B71; }

/*=============================================>>>>>
= Common =
===============================================>>>>>*/
body {
  background-color: #ffffff;
  position: relative;
  width: 100%; }
  body.nav-open {
    overflow-y: hidden;
    position: fixed;
    max-height: 100vh; }

.default-content {
  padding: 50px 0 80px; }

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw; }
  #app main {
    flex: 1 1; }

.small-tablet-up {
  display: none; }
  @media (min-width: 960px) {
    .small-tablet-up {
      display: block; } }

.wrap {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .wrap.wide {
    max-width: 1450px; }

.half-wrap {
  max-width: calc(1300px / 2); }
  .half-wrap.half-wrap-left {
    padding-left: 20px;
    margin-left: auto; }
  .half-wrap.half-wrap-right {
    padding-right: 20px;
    margin-right: auto; }

.animated-underline {
  text-decoration: none !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(currentColor), to(currentColor));
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  -webkit-transition: background-size .3s;
  transition: background-size .3s; }
  .animated-underline:hover {
    background-size: 100% 2px; }

@media (min-width: 1080px) {
  .animated-border:hover:before, .animated-border:hover:after {
    width: 100%;
    height: 100%; }
  .animated-border:hover:before {
    border-top-color: #ffffff;
    border-right-color: #ffffff;
    -webkit-transition: width 0.1s ease-out, height 0.2s ease-out 0.1s;
    transition: width 0.1s ease-out, height 0.2s ease-out 0.1s; }
  .animated-border:hover:after {
    border-bottom-color: #ffffff;
    border-left-color: #ffffff;
    -webkit-transition: border-color 0s ease-out 0.3s, width 0.15s ease-out 0.45s, height 0.15s ease-out 0.6s;
    transition: border-color 0s ease-out 0.3s, width 0.15s ease-out 0.45s, height 0.15s ease-out 0.6s; }
  .animated-border:before, .animated-border:after {
    position: absolute;
    content: '';
    border: 3px solid transparent;
    width: 0;
    height: 0;
    box-sizing: border-box;
    z-index: 2; }
  .animated-border:before {
    top: 0;
    left: 0; }
  .animated-border:after {
    bottom: 0;
    right: 0; } }

.styled-button {
  display: inline-block;
  background-color: #004F6E;
  padding: 15px 40px;
  line-height: calc(26/16);
  position: relative;
  border-radius: 2px; }
  .styled-button:before {
    border-radius: 2px;
    content: '';
    background-color: #076286;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    -webkit-transition: width 0.5s ease;
    transition: width 0.5s ease;
    z-index: 1; }
    @media (max-width: 1079px) {
      .styled-button:before {
        display: none; } }
  .styled-button span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    position: relative;
    z-index: 2; }
  .styled-button.light {
    color: #004F6E;
    background-color: #ffffff; }
    .styled-button.light span {
      color: #004F6E; }
    .styled-button.light:before {
      background-color: #EEECEA; }
  .styled-button:hover, .styled-button:focus, .styled-button:active {
    text-decoration: none; }
  .styled-button:hover:before {
    width: 100%; }

form {
  display: flex;
  flex-direction: column; }
  form label {
    display: block;
    width: 100%;
    display: none; }
    form label span {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-size: 22px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #004F6E;
      margin-bottom: 5px; }
      @media (max-width: 1079px) {
        form label span {
          font-size: 20px; } }
      @media (max-width: 959px) {
        form label span {
          font-size: 18px; } }
      @media (max-width: 749px) {
        form label span {
          font-size: 16px; } }
  form input, form textarea, form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: calc(24/16);
    font-weight: 400;
    color: #6E675B;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    padding: 20px;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-bottom: 8px;
    font-size: 16px !important; }
    @media (max-width: 549px) {
      form input, form textarea, form select {
        font-size: 14px; } }
    form input:last-child, form textarea:last-child, form select:last-child {
      margin-bottom: 0; }
    form input:active, form input:focus, form textarea:active, form textarea:focus, form select:active, form select:focus {
      outline: none; }
    form input::-webkit-input-placeholder, form textarea::-webkit-input-placeholder, form select::-webkit-input-placeholder {
      color: rgba(129, 123, 113, 0.45); }
    form input::-moz-placeholder, form textarea::-moz-placeholder, form select::-moz-placeholder {
      color: rgba(129, 123, 113, 0.45); }
    form input:-ms-input-placeholder, form textarea:-ms-input-placeholder, form select:-ms-input-placeholder {
      color: rgba(129, 123, 113, 0.45); }
    form input::-ms-input-placeholder, form textarea::-ms-input-placeholder, form select::-ms-input-placeholder {
      color: rgba(129, 123, 113, 0.45); }
    form input::placeholder, form textarea::placeholder, form select::placeholder {
      color: rgba(129, 123, 113, 0.45); }
    form input.dark, form textarea.dark, form select.dark {
      border: 2px solid #F2F2F2;
      background-color: #F2F2F2; }
  form input, form select {
    max-height: 65px; }
  form select {
    position: relative;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMjIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93RG93bk1pbmsiIGZpbGw9IiM4MTdCNzEiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iRmlsbC0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS4wMDAwMDAsIDUuNTAwMDAwKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2LjMyNDI4NjkyIC01LjUgNS41IC00LjY3NDQyMTAzIDE0Ljc3NjAyOTkgNS41IDUuNSAxNS42NzQ0MjEgNi4zMjQyODY5MiAxNi41IDE2LjUgNS41NzUzNjMyOCI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center right 5%; }
    form select option:disabled {
      color: rgba(129, 123, 113, 0.45); }
  form input {
    line-height: 1; }
  form textarea {
    line-height: 1.75em;
    resize: none; }
  form button[type=submit] {
    margin: 0;
    padding: 15px 45px;
    margin: 40px auto 0; }
    form button[type=submit]:hover {
      cursor: pointer; }
    form button[type=submit]:focus, form button[type=submit]:active {
      outline: none; }

.formbucket-field {
  display: none; }
