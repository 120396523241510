/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.footer-nav {
  display: block;
  width: 100%;
  padding: 60px 0 0; }
  .footer-nav .nav-cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }
    .footer-nav .nav-cols .nav-col {
      display: flex;
      flex-direction: column;
      width: 21%;
      padding-right: 40px;
      margin-bottom: 20px; }
      .footer-nav .nav-cols .nav-col:nth-child(5n + 1) {
        width: 16%; }
      .footer-nav .nav-cols .nav-col .nav-link > a {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 20px;
        line-height: calc(32/20);
        color: #6E675B;
        color: #ffffff;
        display: block;
        margin-bottom: 18px;
        line-height: 1.2em; }
        @media (max-width: 1079px) {
          .footer-nav .nav-cols .nav-col .nav-link > a {
            font-size: 18px; } }
      .footer-nav .nav-cols .nav-col .nav-link.lonely-link > a {
        margin-bottom: 3px; }
      .footer-nav .nav-cols .nav-col .nav-link:not(:first-child) > a {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B;
        color: rgba(255, 255, 255, 0.5);
        display: block;
        margin-bottom: 8px; }
        @media (max-width: 549px) {
          .footer-nav .nav-cols .nav-col .nav-link:not(:first-child) > a {
            font-size: 14px; } }
      .footer-nav .nav-cols .nav-col .nav-link .nav-children {
        margin-bottom: 10px; }
        .footer-nav .nav-cols .nav-col .nav-link .nav-children .nav-child.hidden {
          display: none; }
        .footer-nav .nav-cols .nav-col .nav-link .nav-children .nav-child a {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: calc(24/16);
          font-weight: 400;
          color: #6E675B;
          color: rgba(255, 255, 255, 0.5);
          display: block;
          margin-bottom: 8px; }
          @media (max-width: 549px) {
            .footer-nav .nav-cols .nav-col .nav-link .nav-children .nav-child a {
              font-size: 14px; } }
