/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.list-block {
  background-color: #EEECEA;
  padding-top: 200px;
  padding-bottom: 200px; }
  @media (max-width: 549px) {
    .list-block {
      padding-top: 130px;
      padding-bottom: 70px; } }
  .list-block .wrap {
    position: relative;
    text-align: center; }
    .list-block .wrap .list-container {
      position: relative;
      display: inline-block;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding: 70px;
      border: 3px solid #ffffff; }
      @media (max-width: 549px) {
        .list-block .wrap .list-container {
          max-width: none;
          width: 100%; } }
      @media (max-width: 399px) {
        .list-block .wrap .list-container {
          padding: 70px 20px 50px; } }
      .list-block .wrap .list-container .logo {
        width: 80px;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        bottom: calc(100% - 50px);
        background: #EEECEA; }
        .list-block .wrap .list-container .logo img {
          height: 100%; }
      .list-block .wrap .list-container .title {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        margin-bottom: 40px; }
        @media (max-width: 1339px) {
          .list-block .wrap .list-container .title {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .list-block .wrap .list-container .title {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .list-block .wrap .list-container .title {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .list-block .wrap .list-container .title {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .list-block .wrap .list-container .title {
            font-size: 23px; } }
        @media (max-width: 1079px) {
          .list-block .wrap .list-container .title {
            font-size: 26px !important; } }
        @media (max-width: 399px) {
          .list-block .wrap .list-container .title {
            margin-bottom: 20px; } }
      .list-block .wrap .list-container .list-items .list-item {
        padding: 10px 20px;
        border-bottom: 1px solid #ffffff; }
        .list-block .wrap .list-container .list-items .list-item p {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: calc(24/16);
          font-weight: 400;
          color: #6E675B;
          margin: 0; }
          @media (max-width: 549px) {
            .list-block .wrap .list-container .list-items .list-item p {
              font-size: 14px; } }
