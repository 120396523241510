/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.team-glossary {
  position: relative;
  padding-top: 160px;
  padding-bottom: 130px;
  background-color: #EEECEA; }
  @media (max-width: 1079px) {
    .team-glossary {
      padding-top: 0; } }
  @media (max-width: 959px) {
    .team-glossary {
      padding-bottom: 10px; } }
  .team-glossary .top-bar {
    position: absolute;
    bottom: 100%;
    width: 100%; }
    @media (max-width: 1079px) {
      .team-glossary .top-bar {
        position: static;
        background: #D3EDEF;
        margin-bottom: 100px; } }
    @media (max-width: 549px) {
      .team-glossary .top-bar {
        padding-top: 30px; } }
    .team-glossary .top-bar .wrap {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 0 20px; }
      .team-glossary .top-bar .wrap .search-toggle {
        position: absolute;
        right: calc(100% - 20px);
        top: 0;
        height: 100%;
        margin-right: 0;
        background-color: #ffffff; }
      .team-glossary .top-bar .wrap button {
        outline: none;
        cursor: pointer; }
      .team-glossary .top-bar .wrap .search-toggle {
        border-right: 1px solid #D3EDEF;
        border-top: none;
        border-bottom: none;
        border-left: none;
        box-sizing: border-box;
        background-color: #ffffff;
        padding: 0 20px; }
        .team-glossary .top-bar .wrap .search-toggle svg {
          width: 22px;
          height: 22px; }
      .team-glossary .top-bar .wrap .search-bar {
        padding: 0;
        border: none;
        position: absolute;
        top: 0;
        left: 20px;
        height: 100%;
        box-sizing: border-box;
        z-index: 1;
        width: 0px;
        font-family: 'Roboto', sans-serif;
        font-size: 11px;
        line-height: calc(15/11);
        font-weight: 400;
        color: #6E675B;
        font-size: 14px;
        outline: none;
        -webkit-transition: width 0.3s cubic-bezier(1, 0, 0, 0), padding 0.8s ease;
        transition: width 0.3s cubic-bezier(1, 0, 0, 0), padding 0.8s ease; }
        .team-glossary .top-bar .wrap .search-bar.active {
          padding: 20px;
          visibility: visible;
          height: 100%;
          width: calc(100% - 40px);
          -webkit-transition: width 0.4s cubic-bezier(1, 0, 0, 0), padding 0.8s ease;
          transition: width 0.4s cubic-bezier(1, 0, 0, 0), padding 0.8s ease; }
      .team-glossary .top-bar .wrap .filter {
        position: relative;
        flex: 1 1;
        padding: 20px;
        background-color: #ffffff;
        border-right: 1px solid #D3EDEF;
        border-top: none;
        border-bottom: none;
        border-left: none;
        box-sizing: border-box;
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71;
        -webkit-transition: .2s ease;
        transition: .2s ease; }
        @media (max-width: 959px) {
          .team-glossary .top-bar .wrap .filter {
            min-width: 100%;
            text-align: left; } }
        @media (max-width: 1199px) {
          .team-glossary .top-bar .wrap .filter {
            padding: 20px; } }
        .team-glossary .top-bar .wrap .filter.show-filters {
          display: flex;
          justify-content: space-between; }
          @media (min-width: 960px) {
            .team-glossary .top-bar .wrap .filter.show-filters {
              display: none; } }
          .team-glossary .top-bar .wrap .filter.show-filters:after {
            content: '';
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMjIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93RG93bk1pbmsiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iRmlsbC0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS4wMDAwMDAsIDUuNTAwMDAwKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2LjMyNDI4NjkyIC01LjUgNS41IC00LjY3NDQyMTAzIDE0Ljc3NjAyOTkgNS41IDUuNSAxNS42NzQ0MjEgNi4zMjQyODY5MiAxNi41IDE2LjUgNS41NzUzNjMyOCI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center;
            width: 18px;
            height: 18px;
            display: block; }
          .team-glossary .top-bar .wrap .filter.show-filters.open:after {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMjIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93VXBNaW5rIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkZpbGwtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCA1LjUwMDAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2LjMyNDI4NjkyIC01LjUgNS41IC00LjY3NDQyMTAzIDE0Ljc3NjAyOTkgNS41IDUuNSAxNS42NzQ0MjEgNi4zMjQyODY5MiAxNi41IDE2LjUgNS41NzUzNjMyOCI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==); }
        .team-glossary .top-bar .wrap .filter:last-of-type {
          border-right: none; }
        @media (min-width: 1080px) {
          .team-glossary .top-bar .wrap .filter:hover, .team-glossary .top-bar .wrap .filter.active {
            background-color: #004F6E;
            color: #ffffff; } }
  .team-glossary .wrap {
    padding: 0 50px 0 20px; }
    .team-glossary .wrap .team-members {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      @media (max-width: 1079px) {
        .team-glossary .wrap .team-members {
          padding-top: 90px; } }
      .team-glossary .wrap .team-members .team-member {
        position: relative;
        width: calc((100% / 3) - ((56px * 2) / 3));
        margin-right: 56px;
        margin-bottom: 75px;
        text-decoration: none;
        background-color: #ffffff;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s; }
        @media (max-width: 959px) {
          .team-glossary .wrap .team-members .team-member {
            width: calc((100% / 2) - (56px / 2)); } }
        @media (max-width: 549px) {
          .team-glossary .wrap .team-members .team-member {
            width: 100%;
            margin-right: 0; } }
        @media (max-width: 959px) {
          .team-glossary .wrap .team-members .team-member:nth-child(2n) {
            margin-right: 0; } }
        @media (min-width: 960px) {
          .team-glossary .wrap .team-members .team-member:nth-child(3n) {
            margin-right: 0; } }
        .team-glossary .wrap .team-members .team-member .photo {
          position: relative;
          width: 100%;
          padding-bottom: 130%; }
          .team-glossary .wrap .team-members .team-member .photo .gatsby-image {
            top: -30px;
            right: -30px; }
        .team-glossary .wrap .team-members .team-member .text {
          padding: 0 30px; }
          .team-glossary .wrap .team-members .team-member .text .name {
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 23px;
            line-height: calc(32/23);
            color: #004F6E;
            margin-bottom: 15px; }
            @media (max-width: 1079px) {
              .team-glossary .wrap .team-members .team-member .text .name {
                font-size: 21px; } }
          .team-glossary .wrap .team-members .team-member .text .role {
            position: relative;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: calc(24/16);
            font-weight: 400;
            color: #6E675B;
            margin-bottom: 0;
            padding-bottom: 20px; }
            @media (max-width: 549px) {
              .team-glossary .wrap .team-members .team-member .text .role {
                font-size: 14px; } }
            .team-glossary .wrap .team-members .team-member .text .role:after {
              position: absolute;
              bottom: -2px;
              left: 0;
              content: '';
              width: 30%;
              height: 5px;
              background-color: #D9D9D9;
              z-index: 3;
              -webkit-transition: background-color 0.3s 0.5s;
              transition: background-color 0.3s 0.5s; }
        @media (min-width: 1080px) {
          .team-glossary .wrap .team-members .team-member:hover {
            background-color: transparent; }
            .team-glossary .wrap .team-members .team-member:hover .photo {
              opacity: 1;
              position: relative;
              z-index: 5; }
            .team-glossary .wrap .team-members .team-member:hover .text .role:after {
              background-color: #004F6E; } }
