/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
header {
  position: relative;
  width: 100vw; }
  header .header-main {
    padding: 30px 0px;
    background-color: #004F6E; }
    @media (max-width: 1079px) {
      header .header-main {
        padding: 10px 0; } }
    header .header-main .logo {
      position: relative;
      left: -10px; }
      @media (max-width: 1339px) {
        header .header-main .logo {
          background-image: url(/static/logo-no-tag-f79180926262828ee83f2d55b5f0bf43.svg);
          width: 144px;
          height: 62px;
          background-size: contain; } }
      @media (max-width: 749px) {
        header .header-main .logo {
          width: 104px;
          height: 45px;
          left: -5px; } }
    header .header-main .wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      header .header-main .wrap .controls {
        display: flex;
        flex-direction: row;
        align-items: center; }
        header .header-main .wrap .controls button {
          background: none;
          border: none;
          padding: 0;
          margin-right: 30px; }
          header .header-main .wrap .controls button:last-child {
            margin-right: 0; }
          header .header-main .wrap .controls button svg {
            width: 24px;
            height: 24px; }
          header .header-main .wrap .controls button:active, header .header-main .wrap .controls button:focus {
            outline: none; }
          header .header-main .wrap .controls button:hover {
            cursor: pointer; }
        header .header-main .wrap .controls .icon-hamburger {
          width: 24px;
          height: 24px;
          position: relative;
          outline: none;
          cursor: pointer;
          border: 0;
          background: none;
          padding: 0; }
          header .header-main .wrap .controls .icon-hamburger:before, header .header-main .wrap .controls .icon-hamburger:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: #ffffff;
            -webkit-transition: -webkit-transform 0.3s;
            transition: -webkit-transform 0.3s;
            transition: transform 0.3s;
            transition: transform 0.3s, -webkit-transform 0.3s; }
          header .header-main .wrap .controls .icon-hamburger:after {
            margin: 8px 0 0; }
          header .header-main .wrap .controls .icon-hamburger.active:before {
            position: absolute;
            top: 11px;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg); }
          header .header-main .wrap .controls .icon-hamburger.active:after {
            position: absolute;
            bottom: 11px;
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); }
