/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.diagram-block {
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(#ffffff), color-stop(50%, #ffffff), color-stop(#D3EDEF), to(#D3EDEF));
  background: linear-gradient(to right, #ffffff, #ffffff 50%, #D3EDEF, #D3EDEF 100%); }
  .diagram-block.has-outcome .wrap .steps-wrapper .steps .step {
    margin-right: 45px; }
    @media (max-width: 959px) {
      .diagram-block.has-outcome .wrap .steps-wrapper .steps .step {
        margin-right: 0;
        margin-bottom: 64px; } }
    .diagram-block.has-outcome .wrap .steps-wrapper .steps .step .step-text .text {
      font-size: 1em; }
    .diagram-block.has-outcome .wrap .steps-wrapper .steps .step:after {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      content: '+';
      font-size: 50px;
      color: #4AA7CD; }
      @media (min-width: 960px) {
        .diagram-block.has-outcome .wrap .steps-wrapper .steps .step:after {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          left: calc(100% + 5px); } }
      @media (max-width: 959px) {
        .diagram-block.has-outcome .wrap .steps-wrapper .steps .step:after {
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          top: calc(100% + 16px); } }
    .diagram-block.has-outcome .wrap .steps-wrapper .steps .step:last-child:after {
      content: ''; }
  .diagram-block .wrap {
    background-color: #ffffff;
    padding-right: 280px;
    padding-top: 130px;
    padding-bottom: 150px; }
    @media (max-width: 1339px) {
      .diagram-block .wrap {
        padding-right: 140px;
        padding-left: 140px; } }
    @media (max-width: 1079px) {
      .diagram-block .wrap {
        padding-right: 40px;
        padding-left: 40px; } }
    @media (max-width: 549px) {
      .diagram-block .wrap {
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 50px;
        padding-top: 50px; } }
    .diagram-block .wrap .title {
      text-align: center; }
      .diagram-block .wrap .title h2 {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        margin-bottom: 45px; }
        @media (max-width: 1339px) {
          .diagram-block .wrap .title h2 {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .diagram-block .wrap .title h2 {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .diagram-block .wrap .title h2 {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .diagram-block .wrap .title h2 {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .diagram-block .wrap .title h2 {
            font-size: 23px; } }
    .diagram-block .wrap .logo-block {
      text-align: center;
      margin-bottom: 40px; }
      .diagram-block .wrap .logo-block img {
        margin: 0 auto;
        height: 115px; }
    .diagram-block .wrap .steps-wrapper {
      position: relative;
      margin-bottom: 130px; }
      @media (max-width: 959px) {
        .diagram-block .wrap .steps-wrapper {
          margin-bottom: 100px; } }
      .diagram-block .wrap .steps-wrapper.visible .steps .step .step-text .number {
        opacity: 1; }
      .diagram-block .wrap .steps-wrapper .steps {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center; }
        @media (max-width: 959px) {
          .diagram-block .wrap .steps-wrapper .steps {
            flex-direction: column; } }
        .diagram-block .wrap .steps-wrapper .steps .step {
          position: relative;
          flex: 1 1;
          margin-right: 14px;
          border-radius: 50%;
          background-color: #D3EDEF; }
          @media (max-width: 959px) {
            .diagram-block .wrap .steps-wrapper .steps .step {
              max-width: 170px;
              max-height: 170px;
              min-width: 170px;
              min-height: 170px;
              margin-right: 0;
              margin-bottom: 14px; } }
          .diagram-block .wrap .steps-wrapper .steps .step:last-child {
            margin-right: 0;
            margin-bottom: 0; }
          .diagram-block .wrap .steps-wrapper .steps .step:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .diagram-block .wrap .steps-wrapper .steps .step .step-text {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translateY(-50%) translateX(-50%);
                    transform: translateY(-50%) translateX(-50%);
            text-align: center; }
            .diagram-block .wrap .steps-wrapper .steps .step .step-text .number {
              display: block;
              font-family: 'SignaSerifPro', serif;
              font-weight: 600;
              color: #ffffff;
              font-size: 45px;
              margin-bottom: 18px;
              opacity: 0;
              -webkit-transition: opacity .9s;
              transition: opacity .9s; }
            .diagram-block .wrap .steps-wrapper .steps .step .step-text .text {
              font-family: 'SignaSerifPro', serif;
              font-weight: 600;
              font-size: 16px;
              line-height: calc(32/16);
              color: #004F6E; }
      .diagram-block .wrap .steps-wrapper .outcome {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-top: 70px; }
        @media (max-width: 959px) {
          .diagram-block .wrap .steps-wrapper .outcome {
            justify-content: center;
            flex-direction: column-reverse; } }
        .diagram-block .wrap .steps-wrapper .outcome:before {
          content: '=';
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          font-size: 50px;
          color: #4AA7CD;
          bottom: calc(100% + 20px); }
        .diagram-block .wrap .steps-wrapper .outcome .subtitle {
          flex: 2 1;
          margin-left: 60px;
          margin-right: 30px;
          display: flex;
          align-items: center;
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: calc(24/16);
          font-weight: 400;
          color: #6E675B; }
          @media (max-width: 549px) {
            .diagram-block .wrap .steps-wrapper .outcome .subtitle {
              font-size: 14px; } }
          @media (max-width: 549px) {
            .diagram-block .wrap .steps-wrapper .outcome .subtitle {
              margin: 0; } }
        .diagram-block .wrap .steps-wrapper .outcome .text {
          position: relative;
          text-align: center;
          align-items: center;
          flex: 1 1;
          border-radius: 50%;
          background-color: #D3EDEF;
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 20px;
          line-height: calc(32/20);
          color: #004F6E;
          font-size: 1.1em; }
          @media (max-width: 1079px) {
            .diagram-block .wrap .steps-wrapper .outcome .text {
              font-size: 18px; } }
          @media (max-width: 959px) {
            .diagram-block .wrap .steps-wrapper .outcome .text {
              max-width: 170px;
              max-height: 170px;
              min-width: 170px;
              min-height: 170px;
              margin: 0 auto 50px; } }
          .diagram-block .wrap .steps-wrapper .outcome .text span {
            display: block;
            width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translateY(-50%) translateX(-50%);
                    transform: translateY(-50%) translateX(-50%); }
          .diagram-block .wrap .steps-wrapper .outcome .text:before {
            content: '';
            display: block;
            padding-top: 100%; }
        .diagram-block .wrap .steps-wrapper .outcome .flex-filler {
          flex: 2 1;
          margin-right: 60px;
          margin-left: 30px; }
          @media (max-width: 959px) {
            .diagram-block .wrap .steps-wrapper .outcome .flex-filler {
              display: none; } }
    .diagram-block .wrap .diagram-text .headline {
      max-width: 50%; }
      @media (max-width: 749px) {
        .diagram-block .wrap .diagram-text .headline {
          max-width: none; } }
      .diagram-block .wrap .diagram-text .headline h2 {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        margin-bottom: 50px; }
        @media (max-width: 1339px) {
          .diagram-block .wrap .diagram-text .headline h2 {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .diagram-block .wrap .diagram-text .headline h2 {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .diagram-block .wrap .diagram-text .headline h2 {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .diagram-block .wrap .diagram-text .headline h2 {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .diagram-block .wrap .diagram-text .headline h2 {
            font-size: 23px; } }
    .diagram-block .wrap .diagram-text .columns {
      display: flex;
      flex-direction: row;
      padding-left: 100px; }
      @media (max-width: 1079px) {
        .diagram-block .wrap .diagram-text .columns {
          padding-left: 0; } }
      @media (max-width: 749px) {
        .diagram-block .wrap .diagram-text .columns {
          flex-direction: column; } }
      .diagram-block .wrap .diagram-text .columns .column {
        flex: 1 1; }
        .diagram-block .wrap .diagram-text .columns .column:first-child {
          margin-right: 55px; }
          @media (max-width: 959px) {
            .diagram-block .wrap .diagram-text .columns .column:first-child {
              margin-right: 0; } }
        .diagram-block .wrap .diagram-text .columns .column p {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: calc(24/16);
          font-weight: 400;
          color: #6E675B; }
          @media (max-width: 549px) {
            .diagram-block .wrap .diagram-text .columns .column p {
              font-size: 14px; } }
