/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.result-filters {
  margin-bottom: 80px;
  border-bottom: 4px solid #D3EDEF; }
  @media (max-width: 749px) {
    .result-filters {
      margin-bottom: 40px; } }
  .result-filters .filter {
    display: inline-block;
    padding-right: 40px;
    font-family: 'Karla', sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: calc(26/14);
    font-weight: 400;
    text-transform: uppercase;
    color: #817B71;
    margin-bottom: 5px; }
    .result-filters .filter:hover {
      cursor: pointer;
      color: #004F6E;
      font-weight: 700; }
    .result-filters .filter.active {
      color: #004F6E;
      font-weight: 700; }

.results {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media (max-width: 1079px) {
    .results {
      justify-content: space-between; } }
  .results .result {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc((100% / 3) - ((20px * 2) / 3));
    margin-right: 20px;
    margin-bottom: 30px; }
    @media (max-width: 1079px) {
      .results .result {
        width: calc(50% - 10px);
        margin-right: 0; } }
    @media (max-width: 749px) {
      .results .result {
        width: 100%; } }
    .results .result:nth-child(3n) {
      margin-right: 0; }
    .results .result .meta {
      display: block;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      line-height: 1em;
      padding: 0 20px; }
      .results .result .meta p {
        margin-bottom: 0;
        line-height: 1.3;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%; }
      .results .result .meta .author {
        font-weight: 700; }
    .results .result .container {
      flex: 100% 1;
      position: relative;
      padding: 5px 20px 20px; }
      .results .result .container:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        height: calc(100% - 115px);
        border: 2px solid #D9D9D9;
        z-index: 0; }
      .results .result .container > * {
        position: relative;
        z-index: 1; }
      .results .result .container .image {
        position: relative;
        display: flex;
        width: 100%;
        height: 230px;
        margin: 0 auto 20px; }
        .results .result .container .image .placeholder {
          height: 100%;
          width: 100%;
          background: #EEECEA; }
        .results .result .container .image img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover;
          -o-object-position: center;
             object-position: center; }
      .results .result .container .title {
        margin-bottom: 15px;
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 20px;
        line-height: calc(32/20);
        color: #004F6E; }
        @media (max-width: 1079px) {
          .results .result .container .title {
            font-size: 18px; } }
        .results .result .container .title a {
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 20px;
          line-height: calc(32/20);
          color: #004F6E; }
          @media (max-width: 1079px) {
            .results .result .container .title a {
              font-size: 18px; } }
      .results .result .container .excerpt {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B; }
        @media (max-width: 549px) {
          .results .result .container .excerpt {
            font-size: 14px; } }
      .results .result .container .styled-button {
        margin-top: 20px; }
