/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.subscribe-form-block {
  padding: 85px 20px;
  background-color: #F2F2F2; }
  @media (max-width: 1339px) {
    .subscribe-form-block {
      padding: 50px 20px 60px; } }
  @media (max-width: 399px) {
    .subscribe-form-block {
      padding: 50px 0 60px; } }
  .subscribe-form-block .wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between; }
    @media (max-width: 1339px) {
      .subscribe-form-block .wrap {
        flex-direction: column;
        align-items: flex-start;
        max-width: 600px; } }
    @media (max-width: 1079px) {
      .subscribe-form-block .wrap {
        margin: 0; } }
    .subscribe-form-block .wrap > * {
      margin-right: 50px; }
      .subscribe-form-block .wrap > *:last-child {
        margin-right: 0; }
  .subscribe-form-block .submit-success-message {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .subscribe-form-block .submit-success-message span {
      display: block;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      text-align: center;
      text-transform: uppercase; }
  .subscribe-form-block .monogram {
    width: 75px;
    height: 115px; }
    @media (max-width: 1079px) {
      .subscribe-form-block .monogram {
        margin-bottom: 20px; } }
    .subscribe-form-block .monogram svg {
      fill: #004F6E; }
  .subscribe-form-block .text-col {
    flex: 1.1 1;
    height: 100%;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 1339px) {
      .subscribe-form-block .text-col {
        padding: 0 0 40px;
        margin: 0; } }
    .subscribe-form-block .text-col .label {
      display: block;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      text-transform: uppercase;
      margin-bottom: 25px; }
      @media (max-width: 1079px) {
        .subscribe-form-block .text-col .label {
          margin-bottom: 10px; } }
    .subscribe-form-block .text-col .text {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 23px;
      line-height: calc(32/23);
      color: #6E675B;
      color: #004F6E; }
      @media (max-width: 1079px) {
        .subscribe-form-block .text-col .text {
          font-size: 21px; } }
  .subscribe-form-block .form-col {
    flex: 1 1; }
    @media (max-width: 1339px) {
      .subscribe-form-block .form-col {
        width: 100%; } }
    .subscribe-form-block .form-col .error-message {
      display: block;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      text-transform: uppercase;
      margin-bottom: 25px;
      color: #d9534f; }
    .subscribe-form-block .form-col form {
      display: flex;
      flex-direction: row; }
      .subscribe-form-block .form-col form label {
        margin-bottom: 0;
        display: block; }
        .subscribe-form-block .form-col form label.has-error input {
          border: 1px solid #d9534f;
          border-right: none; }
        .subscribe-form-block .form-col form label input {
          border-right: none;
          border-top-right-radius: none;
          border-bottom-right-radius: none;
          margin: 0; }
      .subscribe-form-block .form-col form button {
        margin-top: 0;
        border: 1px solid #004F6E;
        border-radius: 0 2px 2px 0; }
        @media (max-width: 399px) {
          .subscribe-form-block .form-col form button {
            padding-left: 15px;
            padding-right: 15px; } }
