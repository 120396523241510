/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.risk-assessment-quiz .quiz-nav {
  position: relative;
  text-align: center; }
  .risk-assessment-quiz .quiz-nav .previous, .risk-assessment-quiz .quiz-nav .next {
    border: none;
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer; }
    @media (max-width: 1079px) {
      .risk-assessment-quiz .quiz-nav .previous, .risk-assessment-quiz .quiz-nav .next {
        width: 100px; } }
    @media (max-width: 549px) {
      .risk-assessment-quiz .quiz-nav .previous, .risk-assessment-quiz .quiz-nav .next {
        width: 80px;
        background-size: 30px; } }
  .risk-assessment-quiz .quiz-nav .previous {
    left: 0;
    border-right: 1px solid #D9D9D9;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93TGVmdHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgMTEuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTcuNTAwMDAwLCAtMTEuMDAwMDAwKSAiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); }
  .risk-assessment-quiz .quiz-nav .next {
    right: 0;
    border-left: 1px solid #D9D9D9;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93UmlnaHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); }
  .risk-assessment-quiz .quiz-nav .title {
    padding: 50px 0; }
    @media (max-width: 1079px) {
      .risk-assessment-quiz .quiz-nav .title {
        padding: 30px 0; } }
    @media (max-width: 549px) {
      .risk-assessment-quiz .quiz-nav .title {
        padding: 20px 90px; } }
    .risk-assessment-quiz .quiz-nav .title h2 {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 36px;
      line-height: calc(48/36);
      color: #004F6E; }
      @media (max-width: 1339px) {
        .risk-assessment-quiz .quiz-nav .title h2 {
          font-size: 32px; } }
      @media (max-width: 1079px) {
        .risk-assessment-quiz .quiz-nav .title h2 {
          font-size: 30px; } }
      @media (max-width: 959px) {
        .risk-assessment-quiz .quiz-nav .title h2 {
          font-size: 28px; } }
      @media (max-width: 749px) {
        .risk-assessment-quiz .quiz-nav .title h2 {
          font-size: 26px; } }
      @media (max-width: 549px) {
        .risk-assessment-quiz .quiz-nav .title h2 {
          font-size: 23px; } }
      @media (max-width: 549px) {
        .risk-assessment-quiz .quiz-nav .title h2 {
          font-size: 18px; } }

.risk-assessment-quiz .quiz-container {
  padding: 140px 0 300px;
  background-color: #F2F2F2; }
  @media (max-width: 1079px) {
    .risk-assessment-quiz .quiz-container {
      padding: 50px 0; } }
  .risk-assessment-quiz .quiz-container .wrap {
    max-width: 800px; }
    .risk-assessment-quiz .quiz-container .wrap .thank-you-container h2 {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 23px;
      line-height: calc(32/23);
      color: #004F6E;
      margin-bottom: 20px; }
      @media (max-width: 1079px) {
        .risk-assessment-quiz .quiz-container .wrap .thank-you-container h2 {
          font-size: 21px; } }
    .risk-assessment-quiz .quiz-container .wrap .thank-you-container p {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      margin-bottom: 50px; }
      @media (max-width: 549px) {
        .risk-assessment-quiz .quiz-container .wrap .thank-you-container p {
          font-size: 14px; } }
    .risk-assessment-quiz .quiz-container .wrap .progress-container {
      margin-bottom: 40px; }
      @media (max-width: 549px) {
        .risk-assessment-quiz .quiz-container .wrap .progress-container {
          margin-bottom: 20px; } }
      .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block {
        position: relative;
        height: 200px;
        margin-bottom: 40px; }
        @media (max-width: 549px) {
          .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block {
            height: 100px; } }
        .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-number {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateY(-50%) translateX(-50%);
                  transform: translateY(-50%) translateX(-50%);
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 220px;
          color: #ffffff;
          top: 40%; }
          @media (max-width: 549px) {
            .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-number {
              font-size: 140px; } }
        .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-text {
          display: block;
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 36px;
          line-height: calc(48/36);
          color: #004F6E;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateY(-50%) translateX(-50%);
                  transform: translateY(-50%) translateX(-50%); }
          @media (max-width: 1339px) {
            .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-text {
              font-size: 32px; } }
          @media (max-width: 1079px) {
            .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-text {
              font-size: 30px; } }
          @media (max-width: 959px) {
            .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-text {
              font-size: 28px; } }
          @media (max-width: 749px) {
            .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-text {
              font-size: 26px; } }
          @media (max-width: 549px) {
            .risk-assessment-quiz .quiz-container .wrap .progress-container .stage-block .stage-text {
              font-size: 23px; } }
      .risk-assessment-quiz .quiz-container .wrap .progress-container .progress-bar {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #D9D9D9;
        max-width: 70%;
        margin: auto; }
        .risk-assessment-quiz .quiz-container .wrap .progress-container .progress-bar .stage {
          flex: 1 1;
          height: 5px; }
          .risk-assessment-quiz .quiz-container .wrap .progress-container .progress-bar .stage.active {
            background-color: #D9D9D9; }
    .risk-assessment-quiz .quiz-container .wrap form.submitted {
      display: none; }
    .risk-assessment-quiz .quiz-container .wrap form .page {
      display: none; }
      .risk-assessment-quiz .quiz-container .wrap form .page.active {
        display: block; }
      .risk-assessment-quiz .quiz-container .wrap form .page > .title {
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71;
        text-align: center;
        margin-bottom: 70px; }
        @media (max-width: 549px) {
          .risk-assessment-quiz .quiz-container .wrap form .page > .title {
            margin-bottom: 30px; } }
      .risk-assessment-quiz .quiz-container .wrap form .page .fields {
        border: 3px solid #ffffff;
        padding: 65px; }
        @media (max-width: 549px) {
          .risk-assessment-quiz .quiz-container .wrap form .page .fields {
            padding: 20px; } }
        .risk-assessment-quiz .quiz-container .wrap form .page .fields .chart {
          height: 200px;
          width: 100%;
          background-image: url(/static/quiz-chart-f8a5592709f331e846a48060fffe5cae.png);
          background-size: contain;
          background-repeat: no-repeat;
          margin-bottom: 20px; }
          @media (max-width: 549px) {
            .risk-assessment-quiz .quiz-container .wrap form .page .fields .chart {
              height: 150px; } }
        .risk-assessment-quiz .quiz-container .wrap form .page .fields .field {
          position: relative;
          margin-bottom: 8px; }
          .risk-assessment-quiz .quiz-container .wrap form .page .fields .field.error {
            border-top: 2px solid #817B71; }
            .risk-assessment-quiz .quiz-container .wrap form .page .fields .field.error.radio-group {
              padding-top: 40px; }
          .risk-assessment-quiz .quiz-container .wrap form .page .fields .field .question {
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 23px;
            line-height: calc(32/23);
            color: #004F6E; }
            @media (max-width: 1079px) {
              .risk-assessment-quiz .quiz-container .wrap form .page .fields .field .question {
                font-size: 21px; } }
          .risk-assessment-quiz .quiz-container .wrap form .page .fields .field input[type="radio"] {
            width: auto;
            display: none; }
          .risk-assessment-quiz .quiz-container .wrap form .page .fields .field input[type="radio"]:checked + label:before {
            background-color: #817B71; }
          .risk-assessment-quiz .quiz-container .wrap form .page .fields .field label {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: calc(24/16);
            font-weight: 400;
            color: #6E675B;
            margin-bottom: 12px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer; }
            @media (max-width: 549px) {
              .risk-assessment-quiz .quiz-container .wrap form .page .fields .field label {
                font-size: 14px; } }
            .risk-assessment-quiz .quiz-container .wrap form .page .fields .field label:before {
              flex-shrink: 0;
              display: inline-block;
              margin-right: 15px;
              content: '';
              width: 24px;
              height: 24px;
              cursor: pointer;
              border-radius: 50%;
              background-color: #ffffff;
              border: 7px solid #ffffff;
              box-sizing: border-box; }
          .risk-assessment-quiz .quiz-container .wrap form .page .fields .field .validation-message {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px 16px;
            font-family: 'Roboto', sans-serif;
            font-size: 10px;
            background-color: #817B71;
            color: #ffffff;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            line-height: 1em;
            display: inline-block;
            margin-bottom: 15px;
            border-bottom-left-radius: 2px; }
        .risk-assessment-quiz .quiz-container .wrap form .page .fields .next-button {
          display: block;
          margin-left: auto;
          margin-right: 0;
          cursor: pointer; }
