/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.map-block {
  position: relative;
  background-color: #EEECEA; }
  .map-block .wrap {
    display: flex;
    flex-direction: row; }
    @media (max-width: 1079px) {
      .map-block .wrap {
        flex-direction: column-reverse;
        padding: 0; } }
    .map-block .wrap .left-col {
      flex: 1 1; }
      .map-block .wrap .left-col .map {
        left: 0;
        position: absolute !important;
        width: 50%;
        top: -120px; }
        @media (max-width: 1079px) {
          .map-block .wrap .left-col .map {
            position: relative !important;
            top: auto;
            height: 370px;
            width: 100%; }
            .map-block .wrap .left-col .map img {
              position: relative; } }
      .map-block .wrap .left-col .address {
        position: absolute;
        width: 450px;
        height: 400px;
        bottom: -100px;
        opacity: 0.5;
        right: calc(50% - 60px);
        background-color: #004F6E;
        z-index: 1;
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 32px;
        line-height: calc(32/32);
        color: #6E675B;
        color: #ffffff;
        line-height: 48px;
        padding: 20px;
        -webkit-transition: right 1s ease, opacity .8s ease;
        transition: right 1s ease, opacity .8s ease; }
        @media (max-width: 1079px) {
          .map-block .wrap .left-col .address {
            font-size: 28px; } }
        @media (max-width: 549px) {
          .map-block .wrap .left-col .address {
            font-size: 23px; } }
        @media (max-width: 1079px) {
          .map-block .wrap .left-col .address {
            position: relative;
            bottom: auto;
            right: 0;
            height: auto;
            width: 100%;
            font-size: 24px;
            line-height: 32px; } }
        .map-block .wrap .left-col .address.visible {
          right: calc(50% - 80px);
          opacity: 1; }
          @media (max-width: 1079px) {
            .map-block .wrap .left-col .address.visible {
              right: 0; } }
        .map-block .wrap .left-col .address .inner-square {
          position: relative;
          padding: 20px;
          width: 100%;
          height: 100%;
          border: 3px solid #ffffff;
          display: flex;
          align-items: flex-end; }
          @media (max-width: 1079px) {
            .map-block .wrap .left-col .address .inner-square {
              align-items: center;
              justify-content: center;
              text-align: center; } }
          @media (max-width: 549px) {
            .map-block .wrap .left-col .address .inner-square {
              justify-content: flex-start;
              align-items: flex-start;
              text-align: left; } }
          .map-block .wrap .left-col .address .inner-square p {
            margin-bottom: 0; }
    .map-block .wrap .right-col {
      flex: 1 1;
      padding-top: 190px;
      padding-bottom: 260px;
      padding-left: 150px; }
      @media (max-width: 1079px) {
        .map-block .wrap .right-col {
          padding-top: 80px;
          padding-bottom: 80px;
          padding-left: 20px;
          padding-right: 20px; } }
      @media (max-width: 549px) {
        .map-block .wrap .right-col {
          padding-top: 40px;
          padding-bottom: 40px; } }
      .map-block .wrap .right-col .headline {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        margin-bottom: 50px; }
        @media (max-width: 1339px) {
          .map-block .wrap .right-col .headline {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .map-block .wrap .right-col .headline {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .map-block .wrap .right-col .headline {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .map-block .wrap .right-col .headline {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .map-block .wrap .right-col .headline {
            font-size: 23px; } }
      .map-block .wrap .right-col .body {
        padding-left: 90px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B; }
        @media (max-width: 549px) {
          .map-block .wrap .right-col .body {
            font-size: 14px; } }
        @media (max-width: 1079px) {
          .map-block .wrap .right-col .body {
            padding-left: 0; } }
        .map-block .wrap .right-col .body .contact-points {
          font-weight: 700; }
          .map-block .wrap .right-col .body .contact-points p {
            margin-bottom: 0; }
            .map-block .wrap .right-col .body .contact-points p a {
              font-family: 'Roboto', sans-serif;
              font-size: 16px;
              line-height: calc(24/16);
              font-weight: 400;
              color: #6E675B;
              font-weight: 700; }
              @media (max-width: 549px) {
                .map-block .wrap .right-col .body .contact-points p a {
                  font-size: 14px; } }
