/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.call-to-action {
  padding: 130px 0;
  text-align: center; }
  @media (max-width: 1079px) {
    .call-to-action {
      padding: 80px 0; } }
  @media (max-width: 549px) {
    .call-to-action {
      padding: 60px 0; } }
  .call-to-action.tertiary {
    background-color: #D3EDEF; }
  .call-to-action.white {
    background-color: #ffffff; }
    .call-to-action.white .wrap .box {
      border: 2px solid #D9D9D9; }
  .call-to-action.off-white {
    background-color: #EEECEA; }
  .call-to-action.primary {
    background-color: #004F6E; }
    .call-to-action.primary .wrap .title h2 {
      color: #3591B6; }
    .call-to-action.primary .wrap .box .headline {
      color: #ffffff; }
  .call-to-action.link .wrap .box {
    padding: 70px 80px; }
  .call-to-action .wrap .title h2 {
    font-family: 'Karla', sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: calc(26/14);
    font-weight: 400;
    text-transform: uppercase;
    color: #817B71;
    text-transform: uppercase;
    margin-bottom: 45px; }
    @media (max-width: 959px) {
      .call-to-action .wrap .title h2 {
        margin-bottom: 20px; } }
  .call-to-action .wrap .box {
    border: 3px solid #ffffff;
    max-width: 800px;
    margin: 0 auto;
    padding: 90px 90px 70px; }
    @media (max-width: 549px) {
      .call-to-action .wrap .box {
        padding: 35px 10px 20px !important; } }
    .call-to-action .wrap .box .headline {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 36px;
      line-height: calc(48/36);
      color: #004F6E;
      display: block;
      margin-bottom: 50px; }
      @media (max-width: 1339px) {
        .call-to-action .wrap .box .headline {
          font-size: 32px; } }
      @media (max-width: 1079px) {
        .call-to-action .wrap .box .headline {
          font-size: 30px; } }
      @media (max-width: 959px) {
        .call-to-action .wrap .box .headline {
          font-size: 28px; } }
      @media (max-width: 749px) {
        .call-to-action .wrap .box .headline {
          font-size: 26px; } }
      @media (max-width: 549px) {
        .call-to-action .wrap .box .headline {
          font-size: 23px; } }
    .call-to-action .wrap .box .body-text {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      margin-bottom: 40px; }
      @media (max-width: 549px) {
        .call-to-action .wrap .box .body-text {
          font-size: 14px; } }
      @media (max-width: 549px) {
        .call-to-action .wrap .box .body-text {
          padding: 0 20px; } }
    .call-to-action .wrap .box .call-to-action-button-container {
      opacity: 0;
      -webkit-transition: opacity 1s;
      transition: opacity 1s; }
      .call-to-action .wrap .box .call-to-action-button-container.visible {
        opacity: 1; }
    @media (max-width: 549px) {
      .call-to-action .wrap .box .styled-button {
        width: 100%; } }
    .call-to-action .wrap .box .quotee-title {
      display: block;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      text-transform: uppercase;
      margin-bottom: 10px;
      opacity: 0;
      -webkit-transition: opacity 0.8s;
      transition: opacity 0.8s; }
      .call-to-action .wrap .box .quotee-title.visible {
        opacity: 1; }
    .call-to-action .wrap .box .quotee-name {
      font-family: 'Roboto', sans-serif;
      font-size: 22px;
      line-height: calc(24/16);
      font-weight: 700;
      color: #004F6E;
      opacity: 0;
      -webkit-transition: opacity 0.8s 0.4s;
      transition: opacity 0.8s 0.4s; }
      @media (max-width: 1079px) {
        .call-to-action .wrap .box .quotee-name {
          font-size: 20px; } }
      @media (max-width: 959px) {
        .call-to-action .wrap .box .quotee-name {
          font-size: 18px; } }
      @media (max-width: 749px) {
        .call-to-action .wrap .box .quotee-name {
          font-size: 16px; } }
      .call-to-action .wrap .box .quotee-name.visible {
        opacity: 1; }
