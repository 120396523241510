/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.error-404-page {
  background-color: #D3EDEF;
  padding-top: 200px;
  padding-bottom: 175px; }
  @media (max-width: 1079px) {
    .error-404-page {
      padding-top: 150px;
      padding-bottom: 150px; } }
  @media (max-width: 549px) {
    .error-404-page {
      padding-top: 80px;
      padding-bottom: 100px; } }
  .error-404-page .wrap {
    text-align: center; }
    .error-404-page .wrap .big-error-text {
      position: relative;
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 150px; }
      @media (max-width: 1079px) {
        .error-404-page .wrap .big-error-text {
          margin-bottom: 100px; } }
      @media (max-width: 549px) {
        .error-404-page .wrap .big-error-text {
          margin-bottom: 60px; } }
      .error-404-page .wrap .big-error-text:before {
        content: '404';
        font-size: 12em;
        color: #ffffff;
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        z-index: 1; }
        @media (max-width: 1079px) {
          .error-404-page .wrap .big-error-text:before {
            font-size: 10em; } }
        @media (max-width: 549px) {
          .error-404-page .wrap .big-error-text:before {
            font-size: 9em; } }
      .error-404-page .wrap .big-error-text h1 {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateY(-50%) translateX(-50%);
                transform: translateY(-50%) translateX(-50%);
        z-index: 2; }
        @media (max-width: 1339px) {
          .error-404-page .wrap .big-error-text h1 {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .error-404-page .wrap .big-error-text h1 {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .error-404-page .wrap .big-error-text h1 {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .error-404-page .wrap .big-error-text h1 {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .error-404-page .wrap .big-error-text h1 {
            font-size: 23px; } }
    .error-404-page .wrap .error-message {
      border: 3px solid #ffffff;
      padding: 50px;
      max-width: 800px;
      margin: 0 auto; }
      @media (max-width: 549px) {
        .error-404-page .wrap .error-message {
          padding: 20px 20px; } }
      .error-404-page .wrap .error-message p {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 23px;
        line-height: calc(32/23);
        color: #004F6E;
        max-width: 500px;
        display: inline-block;
        margin: 0; }
        @media (max-width: 1079px) {
          .error-404-page .wrap .error-message p {
            font-size: 21px; } }
