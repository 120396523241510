/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.article-text-block {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #F2F2F2), color-stop(50%, #D3EDEF));
  background-image: linear-gradient(90deg, #F2F2F2 50%, #D3EDEF 50%); }
  .article-text-block .wrap {
    padding: 130px 0;
    background-color: #F2F2F2; }
    @media (max-width: 1079px) {
      .article-text-block .wrap {
        padding: 100px 0 60px; } }
    @media (max-width: 549px) {
      .article-text-block .wrap {
        padding: 50px 0 60px; } }
    .article-text-block .wrap .columns {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      display: flex;
      flex-direction: row;
      max-width: 82%; }
      @media (max-width: 549px) {
        .article-text-block .wrap .columns {
          font-size: 14px; } }
      @media (max-width: 1339px) {
        .article-text-block .wrap .columns {
          max-width: none;
          padding: 0 20px; } }
      @media (max-width: 959px) {
        .article-text-block .wrap .columns {
          flex-direction: column; } }
      .article-text-block .wrap .columns .col ul {
        padding-bottom: 40px; }
        .article-text-block .wrap .columns .col ul li {
          padding: 10px 0;
          border-bottom: 1px solid #D9D9D9; }
          .article-text-block .wrap .columns .col ul li:before {
            content: '- '; }
      .article-text-block .wrap .columns .left-col {
        flex: 1 1;
        padding-right: 40px; }
        @media (max-width: 1079px) {
          .article-text-block .wrap .columns .left-col {
            padding-right: 10px; } }
        @media (max-width: 549px) {
          .article-text-block .wrap .columns .left-col {
            padding: 0; } }
      .article-text-block .wrap .columns .right-col {
        flex: 1 1;
        padding-left: 40px; }
        @media (max-width: 1079px) {
          .article-text-block .wrap .columns .right-col {
            padding-left: 10px; } }
        @media (max-width: 549px) {
          .article-text-block .wrap .columns .right-col {
            padding: 0; } }
