/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.search-page {
  display: flex;
  flex-direction: column; }
  .search-page .search-input {
    position: relative;
    padding: 123px 0 0px; }
    @media (max-width: 549px) {
      .search-page .search-input {
        padding: 60px 0 20px; } }
    .search-page .search-input span {
      display: block; }
    .search-page .search-input .back-to-hub {
      display: block;
      margin-bottom: 40px;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71; }
      @media (max-width: 549px) {
        .search-page .search-input .back-to-hub {
          margin-bottom: 20px; } }
      .search-page .search-input .back-to-hub:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 30px;
        height: 15px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93TGVmdHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgMTEuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTcuNTAwMDAwLCAtMTEuMDAwMDAwKSAiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 15px; }
    .search-page .search-input input {
      position: relative;
      height: 54px;
      width: 100%;
      background-color: #ffffff;
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 36px;
      line-height: calc(48/36);
      color: #004F6E;
      font-size: 42px;
      font-weight: 700;
      outline: none;
      border: none;
      border-radius: 4px;
      line-height: 1.19;
      padding: 0; }
      @media (max-width: 1339px) {
        .search-page .search-input input {
          font-size: 32px; } }
      @media (max-width: 1079px) {
        .search-page .search-input input {
          font-size: 30px; } }
      @media (max-width: 959px) {
        .search-page .search-input input {
          font-size: 28px; } }
      @media (max-width: 749px) {
        .search-page .search-input input {
          font-size: 26px; } }
      @media (max-width: 549px) {
        .search-page .search-input input {
          font-size: 23px; } }
      @media (max-width: 1079px) {
        .search-page .search-input input {
          font-size: 34px; } }
      @media (max-width: 549px) {
        .search-page .search-input input {
          font-size: 26px; } }
      .search-page .search-input input::-webkit-input-placeholder {
        color: rgba(151, 147, 140, 0.4); }
      .search-page .search-input input::-moz-placeholder {
        color: rgba(151, 147, 140, 0.4); }
      .search-page .search-input input:-ms-input-placeholder {
        color: rgba(151, 147, 140, 0.4); }
      .search-page .search-input input::-ms-input-placeholder {
        color: rgba(151, 147, 140, 0.4); }
      .search-page .search-input input::placeholder {
        color: rgba(151, 147, 140, 0.4); }
    .search-page .search-input .button {
      display: none; }
      @media (max-width: 1079px) {
        .search-page .search-input .button {
          display: inline-block;
          margin-top: 15px; } }
  .search-page .results-container {
    flex: 1 1;
    background-color: #ffffff;
    padding: 30px 0 100px; }
    @media (max-width: 549px) {
      .search-page .results-container {
        padding: 0 0 60px; } }
    .search-page .results-container .result-count, .search-page .results-container .no-results {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      margin-bottom: 40px; }
      @media (max-width: 549px) {
        .search-page .results-container .result-count, .search-page .results-container .no-results {
          font-size: 14px; } }
