/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.page-header-short {
  background-color: #D3EDEF; }
  @media (max-width: 1079px) {
    .page-header-short.blog-header .subtitle.extra-padding {
      padding-bottom: 120px !important; } }
  @media (max-width: 959px) {
    .page-header-short.blog-header .subtitle.extra-padding {
      padding-bottom: 40px !important; } }
  .page-header-short .top {
    position: relative; }
    .page-header-short .top .breadcrumbs {
      position: relative;
      padding: 20px 0;
      border-bottom: 2px solid #ffffff;
      font-family: 'Roboto', sans-serif;
      font-size: 11px;
      line-height: calc(15/11);
      font-weight: 400;
      color: #6E675B;
      font-size: 12px;
      letter-spacing: 1px; }
      @media (max-width: 1079px) {
        .page-header-short .top .breadcrumbs:after {
          position: absolute;
          content: '';
          width: 20px;
          height: 100%;
          right: 0;
          top: 0;
          border-left: 2px solid #ffffff; } }
      @media (max-width: 549px) {
        .page-header-short .top .breadcrumbs .wrap {
          display: none; } }
      .page-header-short .top .breadcrumbs .wrap .crumb {
        font-family: 'Roboto', sans-serif;
        font-size: 11px;
        line-height: calc(15/11);
        font-weight: 400;
        color: #6E675B; }
        .page-header-short .top .breadcrumbs .wrap .crumb:last-child {
          font-weight: 700; }
    .page-header-short .top .title {
      position: relative;
      padding: 35px 0;
      box-sizing: border-box;
      border-bottom: 2px solid #ffffff; }
      @media (max-width: 1079px) {
        .page-header-short .top .title {
          padding: 20px 0;
          padding-right: 20px !important; }
          .page-header-short .top .title:after {
            position: absolute;
            content: '';
            width: 20px;
            height: 100%;
            right: 0;
            top: 0;
            border-left: 2px solid #ffffff; } }
      .page-header-short .top .title h1, .page-header-short .top .title span {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E; }
        @media (max-width: 1339px) {
          .page-header-short .top .title h1, .page-header-short .top .title span {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .page-header-short .top .title h1, .page-header-short .top .title span {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .page-header-short .top .title h1, .page-header-short .top .title span {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .page-header-short .top .title h1, .page-header-short .top .title span {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .page-header-short .top .title h1, .page-header-short .top .title span {
            font-size: 23px; } }
    .page-header-short .top:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;
      height: 100%;
      box-sizing: border-box;
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      background-color: #D3EDEF; }
      @media (max-width: 1079px) {
        .page-header-short .top:after {
          display: none; } }
  .page-header-short .wrap {
    position: relative; }
    .page-header-short .wrap .subtitle {
      padding: 30px 50px 40px 60px;
      border-left: 2px solid #ffffff;
      box-sizing: border-box;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      max-width: 50%; }
      @media (max-width: 549px) {
        .page-header-short .wrap .subtitle {
          font-size: 14px; } }
      @media (max-width: 1079px) {
        .page-header-short .wrap .subtitle {
          max-width: none; } }
      @media (max-width: 549px) {
        .page-header-short .wrap .subtitle {
          padding: 30px 20px 40px; } }
      .page-header-short .wrap .subtitle.extra-padding {
        padding-bottom: 150px; }
        @media (max-width: 1079px) {
          .page-header-short .wrap .subtitle.extra-padding {
            padding-bottom: 40px; } }
