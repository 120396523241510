/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
nav.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 40px; }
  nav.pagination .arrow {
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D9; }
    nav.pagination .arrow a {
      font-size: 12px;
      padding: 10px 18px;
      height: auto;
      width: 70px; }
    nav.pagination .arrow.prev {
      margin-right: 20px; }
      nav.pagination .arrow.prev a {
        content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93TGVmdHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgMTEuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTcuNTAwMDAwLCAtMTEuMDAwMDAwKSAiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); }
    nav.pagination .arrow.next {
      margin-left: 20px; }
      nav.pagination .arrow.next a {
        content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93UmlnaHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); }
  nav.pagination .pages {
    display: flex;
    flex-direction: row; }
    nav.pagination .pages .page-num {
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      padding-left: 8px; }
      nav.pagination .pages .page-num a {
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71; }
      nav.pagination .pages .page-num.active a, nav.pagination .pages .page-num.active span {
        font-weight: bold; }
      nav.pagination .pages .page-num:first-of-type {
        padding-left: 0; }
        nav.pagination .pages .page-num:first-of-type:before {
          content: '';
          padding: 0; }
      nav.pagination .pages .page-num:before {
        content: '/';
        padding-right: 5px;
        font-size: 13px; }
