/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.form-block {
  padding-top: 260px;
  padding-bottom: 250px; }
  @media (max-width: 1079px) {
    .form-block {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (max-width: 749px) {
    .form-block {
      padding-top: 50px; } }
  @media (max-width: 549px) {
    .form-block {
      padding-bottom: 50px; } }
  .form-block .wrap .title {
    font-family: 'Karla', sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: calc(26/14);
    font-weight: 400;
    text-transform: uppercase;
    color: #817B71;
    text-transform: uppercase;
    padding: 40px 0;
    text-align: center; }
    @media (max-width: 549px) {
      .form-block .wrap .title {
        padding: 40px 0 20px; } }
  .form-block .wrap .form-box {
    border: 2px solid #D9D9D9;
    padding: 80px 70px 100px;
    max-width: 786px;
    margin: 0 auto; }
    @media (max-width: 749px) {
      .form-block .wrap .form-box {
        padding: 40px 20px; } }
    .form-block .wrap .form-box .headline {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 36px;
      line-height: calc(48/36);
      color: #004F6E;
      text-align: center;
      margin-bottom: 35px; }
      @media (max-width: 1339px) {
        .form-block .wrap .form-box .headline {
          font-size: 32px; } }
      @media (max-width: 1079px) {
        .form-block .wrap .form-box .headline {
          font-size: 30px; } }
      @media (max-width: 959px) {
        .form-block .wrap .form-box .headline {
          font-size: 28px; } }
      @media (max-width: 749px) {
        .form-block .wrap .form-box .headline {
          font-size: 26px; } }
      @media (max-width: 549px) {
        .form-block .wrap .form-box .headline {
          font-size: 23px; } }
    .form-block .wrap .form-box .subtitle {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B;
      text-align: center;
      margin-bottom: 35px; }
      @media (max-width: 549px) {
        .form-block .wrap .form-box .subtitle {
          font-size: 14px; } }
    @media (max-width: 549px) {
      .form-block .wrap .form-box .styled-button {
        width: 100%;
        margin: 10px auto 0; } }
