/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.page-navigation-block {
  border-top: 1px solid #D9D9D9; }
  @media (max-width: 549px) {
    .page-navigation-block.simple-on-mobile .wrap .left-link, .page-navigation-block.simple-on-mobile .wrap .right-link {
      position: relative;
      min-width: 0;
      padding-top: 20px;
      padding-bottom: 20px; }
      .page-navigation-block.simple-on-mobile .wrap .left-link .link-label, .page-navigation-block.simple-on-mobile .wrap .right-link .link-label {
        display: none; }
      .page-navigation-block.simple-on-mobile .wrap .left-link .headline, .page-navigation-block.simple-on-mobile .wrap .right-link .headline {
        display: none; }
      .page-navigation-block.simple-on-mobile .wrap .left-link .subtitle, .page-navigation-block.simple-on-mobile .wrap .right-link .subtitle {
        display: none; }
      .page-navigation-block.simple-on-mobile .wrap .left-link a, .page-navigation-block.simple-on-mobile .wrap .right-link a {
        position: relative;
        width: 100%;
        height: 50px;
        border: 1px solid #D9D9D9; }
        .page-navigation-block.simple-on-mobile .wrap .left-link a:after, .page-navigation-block.simple-on-mobile .wrap .right-link a:after {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          content: '';
          width: 35px;
          height: 100%;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat; }
    .page-navigation-block.simple-on-mobile .wrap .left-link {
      padding-right: 0;
      padding-left: 20px; }
      .page-navigation-block.simple-on-mobile .wrap .left-link a:after {
        left: 20px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93TGVmdHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgMTEuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTcuNTAwMDAwLCAtMTEuMDAwMDAwKSAiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); }
    .page-navigation-block.simple-on-mobile .wrap .right-link {
      padding-left: 0;
      padding-right: 20px; }
      .page-navigation-block.simple-on-mobile .wrap .right-link a {
        margin-left: -1px; }
        .page-navigation-block.simple-on-mobile .wrap .right-link a:after {
          right: 20px;
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93UmlnaHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); } }
  .page-navigation-block .wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: none;
    padding: 0; }
    .page-navigation-block .wrap .left-link, .page-navigation-block .wrap .right-link {
      position: relative;
      display: flex;
      flex: 1 1;
      padding: 90px 20px 115px;
      text-align: right;
      background-color: #ffffff;
      -webkit-transition: background-color 0.8s;
      transition: background-color 0.8s; }
      @media (max-width: 549px) {
        .page-navigation-block .wrap .left-link:not(.visible), .page-navigation-block .wrap .right-link:not(.visible) {
          padding: 0; } }
      @media (max-width: 549px) {
        .page-navigation-block .wrap .left-link, .page-navigation-block .wrap .right-link {
          min-width: 100%;
          border-bottom: 1px solid #D9D9D9;
          padding: 50px 40px;
          text-align: left; } }
      .page-navigation-block .wrap .left-link .link-label, .page-navigation-block .wrap .right-link .link-label {
        padding-right: 50px; }
        @media (max-width: 549px) {
          .page-navigation-block .wrap .left-link .link-label, .page-navigation-block .wrap .right-link .link-label {
            min-width: 100%;
            display: flex;
            justify-content: space-between; } }
        .page-navigation-block .wrap .left-link .link-label:after, .page-navigation-block .wrap .right-link .link-label:after {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          right: 0;
          content: '';
          width: 35px;
          height: 100%;
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93UmlnaHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat; }
      .page-navigation-block .wrap .left-link a, .page-navigation-block .wrap .right-link a {
        display: block;
        text-decoration: none;
        max-width: calc(1300px / 2);
        min-width: calc(1300px / 2); }
        @media (max-width: 1339px) {
          .page-navigation-block .wrap .left-link a, .page-navigation-block .wrap .right-link a {
            min-width: 100%; } }
      .page-navigation-block .wrap .left-link span, .page-navigation-block .wrap .right-link span {
        display: block; }
      .page-navigation-block .wrap .left-link .link-label, .page-navigation-block .wrap .right-link .link-label {
        position: relative;
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71;
        margin-bottom: 30px; }
      .page-navigation-block .wrap .left-link .headline, .page-navigation-block .wrap .right-link .headline {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        display: inline-block;
        margin-bottom: 8px;
        border-bottom: 2px transparent solid;
        -webkit-transition: border-bottom 0.3s;
        transition: border-bottom 0.3s; }
        @media (max-width: 1339px) {
          .page-navigation-block .wrap .left-link .headline, .page-navigation-block .wrap .right-link .headline {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .page-navigation-block .wrap .left-link .headline, .page-navigation-block .wrap .right-link .headline {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .page-navigation-block .wrap .left-link .headline, .page-navigation-block .wrap .right-link .headline {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .page-navigation-block .wrap .left-link .headline, .page-navigation-block .wrap .right-link .headline {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .page-navigation-block .wrap .left-link .headline, .page-navigation-block .wrap .right-link .headline {
            font-size: 23px; } }
      .page-navigation-block .wrap .left-link .subtitle, .page-navigation-block .wrap .right-link .subtitle {
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71; }
      @media (min-width: 550px) {
        .page-navigation-block .wrap .left-link:hover, .page-navigation-block .wrap .right-link:hover {
          background-color: #D3EDEF; } }
    .page-navigation-block .wrap .left-link {
      justify-content: flex-end; }
      @media (min-width: 550px) {
        .page-navigation-block .wrap .left-link {
          text-align: left;
          border-right: 1px solid #D9D9D9; }
          .page-navigation-block .wrap .left-link .link-label {
            padding-left: 50px; }
            .page-navigation-block .wrap .left-link .link-label:before {
              position: absolute;
              top: 50%;
              -webkit-transform: translateY(-50%);
                      transform: translateY(-50%);
              left: 0;
              content: '';
              width: 35px;
              height: 100%;
              background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93TGVmdHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgMTEuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTcuNTAwMDAwLCAtMTEuMDAwMDAwKSAiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat; }
            .page-navigation-block .wrap .left-link .link-label:after {
              display: none; }
          .page-navigation-block .wrap .left-link:hover {
            border-right-color: #D3EDEF; } }
    .page-navigation-block .wrap .right-link {
      justify-content: flex-start; }
