/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.header-form-block {
  background-color: #F2F2F2;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); }
  .header-form-block.active {
    max-height: 700px;
    padding: 90px 0 110px;
    -webkit-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease; }
    @media (max-width: 1079px) {
      .header-form-block.active {
        padding: 40px 0 80px;
        max-height: 1100px; } }
    @media (max-width: 749px) {
      .header-form-block.active {
        padding: 40px 0; } }
    @media (max-width: 549px) {
      .header-form-block.active {
        padding: 25px 0 50px; } }
    .header-form-block.active .wrap {
      opacity: 1; }
  .header-form-block .wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    opacity: 0;
    position: relative; }
  .header-form-block .hamburger {
    width: 24px;
    height: 24px;
    position: relative;
    outline: none;
    cursor: pointer;
    border: 0;
    background: none;
    padding: 0;
    position: absolute;
    top: 0;
    right: 20px; }
    .header-form-block .hamburger:before, .header-form-block .hamburger:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #004F6E;
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s; }
    .header-form-block .hamburger:after {
      margin: 8px 0 0; }
    .header-form-block .hamburger.active:before {
      position: absolute;
      top: 11px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .header-form-block .hamburger.active:after {
      position: absolute;
      bottom: 11px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .header-form-block .text-col .col-title, .header-form-block .form-col .col-title {
    display: block;
    font-family: 'SignaSerifPro', serif;
    font-weight: 600;
    font-size: 23px;
    line-height: calc(32/23);
    color: #004F6E;
    margin-bottom: 10px; }
    @media (max-width: 1079px) {
      .header-form-block .text-col .col-title, .header-form-block .form-col .col-title {
        font-size: 21px; } }
  .header-form-block .text-col {
    max-width: 400px;
    margin-right: 160px; }
    @media (max-width: 1339px) {
      .header-form-block .text-col {
        margin-right: 80px; } }
    @media (max-width: 1079px) {
      .header-form-block .text-col {
        margin-right: 0;
        min-width: 100%;
        max-width: 100%;
        padding-bottom: 30px; } }
    @media (max-width: 549px) {
      .header-form-block .text-col {
        padding-bottom: 15px; } }
    .header-form-block .text-col .details-group {
      display: flex;
      flex-direction: column;
      margin: 25px 0; }
      .header-form-block .text-col .details-group a {
        display: block;
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 20px;
        line-height: calc(32/20);
        color: #004F6E; }
        @media (max-width: 1079px) {
          .header-form-block .text-col .details-group a {
            font-size: 18px; } }
    .header-form-block .text-col .address {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 20px;
      line-height: calc(32/20);
      color: #004F6E; }
      @media (max-width: 1079px) {
        .header-form-block .text-col .address {
          font-size: 18px; } }
      .header-form-block .text-col .address span {
        display: block;
        max-width: 215px; }
  .header-form-block .form-col {
    flex: 1 1; }
    .header-form-block .form-col form .form-cols {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row; }
      .header-form-block .form-col form .form-cols .form-col {
        flex: 1 1;
        display: flex;
        flex-direction: column; }
        @media (max-width: 749px) {
          .header-form-block .form-col form .form-cols .form-col {
            min-width: 100%; } }
        .header-form-block .form-col form .form-cols .form-col input, .header-form-block .form-col form .form-cols .form-col select, .header-form-block .form-col form .form-cols .form-col textarea {
          margin: 8px 0 0 !important; }
        .header-form-block .form-col form .form-cols .form-col textarea {
          flex: 1 1; }
        .header-form-block .form-col form .form-cols .form-col:nth-child(odd) {
          margin-right: 15px; }
          @media (max-width: 749px) {
            .header-form-block .form-col form .form-cols .form-col:nth-child(odd) {
              margin-right: 0; } }
        .header-form-block .form-col form .form-cols .form-col:nth-child(even) {
          margin-left: 15px; }
          @media (max-width: 749px) {
            .header-form-block .form-col form .form-cols .form-col:nth-child(even) {
              margin-left: 0; } }
    .header-form-block .form-col form .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px; }
      .header-form-block .form-col form .buttons .styled-button {
        margin: 0 !important; }
        @media (max-width: 749px) {
          .header-form-block .form-col form .buttons .styled-button {
            min-width: 100%; } }
