/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.gated-download-form-modal .modal-content {
  max-width: 500px !important; }
  .gated-download-form-modal .modal-content .gated-download-form {
    padding: 20px; }
    .gated-download-form-modal .modal-content .gated-download-form h3 {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 23px;
      line-height: calc(32/23);
      color: #004F6E;
      margin-bottom: 20px; }
      @media (max-width: 1079px) {
        .gated-download-form-modal .modal-content .gated-download-form h3 {
          font-size: 21px; } }
    .gated-download-form-modal .modal-content .gated-download-form .subtitle {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B; }
      @media (max-width: 549px) {
        .gated-download-form-modal .modal-content .gated-download-form .subtitle {
          font-size: 14px; } }
