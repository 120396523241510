/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.links-list {
  background-color: #EEECEA; }
  .links-list .top-block {
    border-bottom: 2px solid #ffffff; }
    @media (max-width: 549px) {
      .links-list .top-block {
        border-bottom: 0; } }
    .links-list .top-block .wrap .inner-wrap {
      padding-top: 130px;
      padding-bottom: 80px;
      padding-left: 50px;
      border-left: 2px solid #ffffff; }
      @media (max-width: 1079px) {
        .links-list .top-block .wrap .inner-wrap {
          padding-top: 80px;
          padding-bottom: 60px;
          border-left: 0;
          padding-left: 0; } }
      @media (max-width: 549px) {
        .links-list .top-block .wrap .inner-wrap {
          padding-top: 80px;
          padding-bottom: 0; } }
      .links-list .top-block .wrap .inner-wrap .title {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 36px;
        line-height: calc(48/36);
        color: #004F6E;
        margin-bottom: 20px;
        max-width: 40%; }
        @media (max-width: 1339px) {
          .links-list .top-block .wrap .inner-wrap .title {
            font-size: 32px; } }
        @media (max-width: 1079px) {
          .links-list .top-block .wrap .inner-wrap .title {
            font-size: 30px; } }
        @media (max-width: 959px) {
          .links-list .top-block .wrap .inner-wrap .title {
            font-size: 28px; } }
        @media (max-width: 749px) {
          .links-list .top-block .wrap .inner-wrap .title {
            font-size: 26px; } }
        @media (max-width: 549px) {
          .links-list .top-block .wrap .inner-wrap .title {
            font-size: 23px; } }
        @media (max-width: 959px) {
          .links-list .top-block .wrap .inner-wrap .title {
            max-width: 80%; } }
        @media (max-width: 549px) {
          .links-list .top-block .wrap .inner-wrap .title {
            max-width: none; } }
      .links-list .top-block .wrap .inner-wrap .subtitle {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B;
        max-width: 40%; }
        @media (max-width: 549px) {
          .links-list .top-block .wrap .inner-wrap .subtitle {
            font-size: 14px; } }
        @media (max-width: 959px) {
          .links-list .top-block .wrap .inner-wrap .subtitle {
            max-width: 80%; } }
        @media (max-width: 549px) {
          .links-list .top-block .wrap .inner-wrap .subtitle {
            max-width: none; } }
  .links-list .bottom-block .wrap .wrap-inner {
    border-left: 2px solid #ffffff;
    padding-top: 130px;
    padding-bottom: 280px;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 1079px) {
      .links-list .bottom-block .wrap .wrap-inner {
        padding-top: 30px;
        padding-bottom: 90px;
        border-left: 0; } }
    .links-list .bottom-block .wrap .wrap-inner .links {
      min-width: 70%; }
      @media (max-width: 749px) {
        .links-list .bottom-block .wrap .wrap-inner .links {
          min-width: 100%; } }
      .links-list .bottom-block .wrap .wrap-inner .links .link {
        position: relative;
        padding: 20px 0;
        border-bottom: 1px solid #ffffff; }
        .links-list .bottom-block .wrap .wrap-inner .links .link:after {
          content: '';
          position: absolute;
          bottom: -1.5px;
          left: 0;
          min-width: 0;
          height: 3px;
          background-color: #ffffff;
          -webkit-transition: min-width .4s ease;
          transition: min-width .4s ease; }
        .links-list .bottom-block .wrap .wrap-inner .links .link:hover:after {
          min-width: 100%; }
        .links-list .bottom-block .wrap .wrap-inner .links .link:hover .role:after {
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzdweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzcgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29uL01lbnVfQXJyb3dSaWdodFdoaXRlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IvCfko4tU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkRlc2t0b3AvQ2FyZWVycy9Qb3NpdGlvbnNBdmFpbGFibGVfSG92ZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzI4LjAwMDAwMCwgLTU3OS4wMDAwMDApIiBmaWxsPSIjODE3QjcxIiBzdHJva2U9IiM4MTdCNzEiIHN0cm9rZS13aWR0aD0iMC41Ij4KICAgICAgICAgICAgPGcgaWQ9Ikljb24vTWVudV9BcnJvd1JpZ2h0U3RvbmVfSG92ZXJTdGF0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMyOS4wMDAwMDAsIDU3OS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+); }
        @media (max-width: 549px) {
          .links-list .bottom-block .wrap .wrap-inner .links .link:first-child {
            border-top: 1px solid #ffffff; } }
        .links-list .bottom-block .wrap .wrap-inner .links .link span {
          font-family: 'Karla', sans-serif;
          font-size: 14px;
          letter-spacing: 2px;
          line-height: calc(26/14);
          font-weight: 400;
          text-transform: uppercase;
          color: #817B71;
          display: block;
          margin-bottom: 10px; }
          @media (max-width: 549px) {
            .links-list .bottom-block .wrap .wrap-inner .links .link span {
              font-size: 12px; } }
        .links-list .bottom-block .wrap .wrap-inner .links .link .inner-link {
          position: relative;
          display: block;
          text-decoration: none;
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 32px;
          line-height: calc(32/32);
          color: #004F6E; }
          @media (max-width: 1079px) {
            .links-list .bottom-block .wrap .wrap-inner .links .link .inner-link {
              font-size: 28px; } }
          @media (max-width: 549px) {
            .links-list .bottom-block .wrap .wrap-inner .links .link .inner-link {
              font-size: 23px; } }
          @media (max-width: 1079px) {
            .links-list .bottom-block .wrap .wrap-inner .links .link .inner-link {
              font-size: 22px; } }
          @media (max-width: 549px) {
            .links-list .bottom-block .wrap .wrap-inner .links .link .inner-link {
              font-size: 16px; } }
          .links-list .bottom-block .wrap .wrap-inner .links .link .inner-link:after {
            content: '';
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            right: 20px;
            width: 40px;
            height: 40px;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93UmlnaHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat; }
            @media (max-width: 549px) {
              .links-list .bottom-block .wrap .wrap-inner .links .link .inner-link:after {
                width: 30px;
                height: 30px; } }
