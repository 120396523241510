/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.team-member-intro .page-header-short .role, .intro-block .page-header-short .role {
  padding-left: 60px;
  padding-top: 30px;
  padding-right: 50px;
  border-left: 2px solid #ffffff;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: calc(24/16);
  font-weight: 400;
  color: #6E675B;
  font-weight: 700;
  max-width: 50%; }
  @media (max-width: 549px) {
    .team-member-intro .page-header-short .role, .intro-block .page-header-short .role {
      font-size: 14px; } }

@media (max-width: 1079px) {
  .team-member-intro .page-header-short .role, .intro-block .page-header-short .role {
    max-width: none; } }

@media (max-width: 549px) {
  .team-member-intro .page-header-short .role, .intro-block .page-header-short .role {
    padding: 30px 20px 0; } }

.team-member-intro .page-header-short .subtitle, .intro-block .page-header-short .subtitle {
  padding-top: 0px; }

.team-member-intro .intro, .intro-block .intro {
  position: relative; }
  .team-member-intro .intro.has-socials .wrap .right, .intro-block .intro.has-socials .wrap .right {
    display: flex !important;
    justify-content: flex-end; }
    @media (max-width: 549px) {
      .team-member-intro .intro.has-socials .wrap .right .image, .intro-block .intro.has-socials .wrap .right .image {
        right: -20px;
        left: auto;
        margin: 0;
        width: calc((100% + 40px) - 80px); } }
    @media (max-width: 399px) {
      .team-member-intro .intro.has-socials .wrap .right .image, .intro-block .intro.has-socials .wrap .right .image {
        width: calc((100% + 40px) - 50px);
        height: 400px; } }
  .team-member-intro .intro .social-links, .intro-block .intro .social-links {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
    .team-member-intro .intro .social-links .link, .intro-block .intro .social-links .link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: #EEECEA;
      font-size: 0;
      border-bottom: 1px solid #ffffff;
      box-sizing: border-box; }
      @media (max-width: 399px) {
        .team-member-intro .intro .social-links .link, .intro-block .intro .social-links .link {
          width: 50px;
          height: 50px; } }
      .team-member-intro .intro .social-links .link a, .intro-block .intro .social-links .link a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 399px) {
          .team-member-intro .intro .social-links .link a svg, .intro-block .intro .social-links .link a svg {
            width: 15px;
            height: 15px; } }
  .team-member-intro .intro .wrap, .intro-block .intro .wrap {
    display: flex;
    flex-direction: row; }
    @media (max-width: 1079px) {
      .team-member-intro .intro .wrap, .intro-block .intro .wrap {
        flex-direction: column-reverse; } }
    .team-member-intro .intro .wrap .left, .intro-block .intro .wrap .left {
      flex: 1 1;
      padding-top: 70px;
      padding-bottom: 130px;
      padding-left: 64px; }
      @media (max-width: 1079px) {
        .team-member-intro .intro .wrap .left, .intro-block .intro .wrap .left {
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 0; } }
      .team-member-intro .intro .wrap .left .contact-info, .intro-block .intro .wrap .left .contact-info {
        padding-top: 60px;
        margin-bottom: 70px; }
        @media (max-width: 1339px) {
          .team-member-intro .intro .wrap .left .contact-info, .intro-block .intro .wrap .left .contact-info {
            padding-left: 100px; } }
        @media (max-width: 1339px) {
          .team-member-intro .intro .wrap .left .contact-info, .intro-block .intro .wrap .left .contact-info {
            padding-left: 40px; } }
        @media (max-width: 1079px) {
          .team-member-intro .intro .wrap .left .contact-info, .intro-block .intro .wrap .left .contact-info {
            margin-bottom: 30px;
            padding-top: 0; } }
        @media (max-width: 549px) {
          .team-member-intro .intro .wrap .left .contact-info, .intro-block .intro .wrap .left .contact-info {
            margin-left: 20px;
            margin-right: 20px;
            padding-left: 0px; } }
        @media (max-width: 399px) {
          .team-member-intro .intro .wrap .left .contact-info, .intro-block .intro .wrap .left .contact-info {
            margin-left: 0px; } }
        .team-member-intro .intro .wrap .left .contact-info span, .intro-block .intro .wrap .left .contact-info span {
          display: block;
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 23px;
          line-height: calc(32/23);
          color: #004F6E; }
          @media (max-width: 1079px) {
            .team-member-intro .intro .wrap .left .contact-info span, .intro-block .intro .wrap .left .contact-info span {
              font-size: 21px; } }
          .team-member-intro .intro .wrap .left .contact-info span a, .intro-block .intro .wrap .left .contact-info span a {
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 23px;
            line-height: calc(32/23);
            color: #004F6E; }
            @media (max-width: 1079px) {
              .team-member-intro .intro .wrap .left .contact-info span a, .intro-block .intro .wrap .left .contact-info span a {
                font-size: 21px; } }
      .team-member-intro .intro .wrap .left .body-text, .intro-block .intro .wrap .left .body-text {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B;
        margin-left: 40px;
        margin-right: 120px; }
        @media (max-width: 549px) {
          .team-member-intro .intro .wrap .left .body-text, .intro-block .intro .wrap .left .body-text {
            font-size: 14px; } }
        @media (max-width: 1079px) {
          .team-member-intro .intro .wrap .left .body-text, .intro-block .intro .wrap .left .body-text {
            margin-left: 40px;
            margin-right: 40px; } }
        @media (max-width: 549px) {
          .team-member-intro .intro .wrap .left .body-text, .intro-block .intro .wrap .left .body-text {
            margin-left: 20px;
            margin-right: 20px; } }
        @media (max-width: 399px) {
          .team-member-intro .intro .wrap .left .body-text, .intro-block .intro .wrap .left .body-text {
            margin: 0; } }
    .team-member-intro .intro .wrap .right, .intro-block .intro .wrap .right {
      position: relative;
      flex: 1 1; }
      @media (max-width: 1079px) {
        .team-member-intro .intro .wrap .right, .intro-block .intro .wrap .right {
          min-width: 100%;
          height: auto;
          margin-top: 30px; } }
      @media (max-width: 549px) {
        .team-member-intro .intro .wrap .right, .intro-block .intro .wrap .right {
          margin-top: 0; } }
      .team-member-intro .intro .wrap .right .image, .intro-block .intro .wrap .right .image {
        position: relative;
        width: 100%;
        top: -100px;
        right: -20px;
        height: 100%;
        max-height: 850px; }
        @media (max-width: 1079px) {
          .team-member-intro .intro .wrap .right .image, .intro-block .intro .wrap .right .image {
            top: 0;
            right: 0;
            width: 370px;
            height: 500px;
            margin: auto; } }
        @media (max-width: 549px) {
          .team-member-intro .intro .wrap .right .image, .intro-block .intro .wrap .right .image {
            right: auto;
            left: -20px;
            margin: 0;
            width: calc(100% + 40px);
            display: block; } }
        .team-member-intro .intro .wrap .right .image img, .intro-block .intro .wrap .right .image img {
          -o-object-position: top center !important;
             object-position: top center !important; }
