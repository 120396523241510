/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.grid-slider-block {
  position: relative;
  margin-top: -50px;
  background: #F2F2F2;
  overflow: hidden; }
  @media (max-width: 1079px) {
    .grid-slider-block {
      margin-top: 0;
      background: #ffffff; } }
  .grid-slider-block:before {
    position: absolute;
    right: 0;
    top: 100px;
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background: #ffffff; }
    @media (max-width: 1079px) {
      .grid-slider-block:before {
        display: none; } }
  .grid-slider-block .tns-outer {
    position: relative; }
  .grid-slider-block .tns-nav {
    padding: 0px 0 0px 90px;
    bottom: auto;
    top: 180px;
    right: 0; }
    @media (max-width: 1079px) {
      .grid-slider-block .tns-nav {
        top: 400px;
        justify-content: flex-start;
        padding: 0 35px;
        max-width: 725px; } }
    @media (max-width: 399px) {
      .grid-slider-block .tns-nav {
        top: 320px; } }
  .grid-slider-block .current-slide-display {
    font-family: 'SignaSerifPro', serif;
    font-weight: 600;
    font-size: 24px;
    color: rgba(129, 123, 113, 0.5);
    letter-spacing: 3.43px;
    text-align: right;
    line-height: calc(26/24);
    margin: 0 0 0 20px; }
  .grid-slider-block .grid-block {
    background: none; }
    .grid-slider-block .grid-block .container .image-col {
      right: -20px;
      opacity: 0.8;
      -webkit-transition: right 1s, opacity 0.8s;
      transition: right 1s, opacity 0.8s; }
    .grid-slider-block .grid-block .container .text-col .outer-container {
      padding-top: 225px; }
      @media (max-width: 1079px) {
        .grid-slider-block .grid-block .container .text-col .outer-container {
          padding: 80px 0 0;
          margin: auto; } }
      .grid-slider-block .grid-block .container .text-col .outer-container .title {
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71; }
        @media (max-width: 1079px) {
          .grid-slider-block .grid-block .container .text-col .outer-container .title {
            text-align: left;
            padding: 0 35px; } }
      .grid-slider-block .grid-block .container .text-col .outer-container .inner-container {
        border: none !important; }
        @media (max-width: 1079px) {
          .grid-slider-block .grid-block .container .text-col .outer-container .inner-container {
            padding: 20px; } }
    .grid-slider-block .grid-block.tns-slide-active .visible .image-col {
      right: 0;
      opacity: 1; }
