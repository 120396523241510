/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.knowledgehub-tabs {
  position: absolute;
  bottom: 0;
  right: 20px; }
  @media (max-width: 959px) {
    .knowledgehub-tabs {
      position: relative;
      right: 0; } }
  .knowledgehub-tabs .tab-container {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 65px; }
    @media (max-width: 959px) {
      .knowledgehub-tabs .tab-container {
        flex-direction: column;
        align-items: flex-start;
        height: auto; } }
    .knowledgehub-tabs .tab-container .hub-search-form {
      height: 100%; }
    .knowledgehub-tabs .tab-container .search-toggle {
      height: 100%;
      display: inline-block;
      margin-right: 0;
      background-color: #ffffff;
      border-right: 1px solid #D3EDEF;
      border-top: none;
      border-bottom: none;
      border-left: none;
      box-sizing: border-box;
      padding: 0 20px; }
      @media (max-width: 959px) {
        .knowledgehub-tabs .tab-container .search-toggle {
          height: 62px;
          width: 62px;
          border: none; } }
      .knowledgehub-tabs .tab-container .search-toggle svg {
        width: 22px;
        height: 22px; }
    .knowledgehub-tabs .tab-container button {
      outline: none;
      cursor: pointer; }
    .knowledgehub-tabs .tab-container .search-bar {
      padding: 0;
      border: none;
      position: absolute;
      top: 0;
      left: 62px;
      height: 100%;
      box-sizing: border-box;
      z-index: 1;
      width: 0px;
      font-family: 'Roboto', sans-serif;
      font-size: 11px;
      line-height: calc(15/11);
      font-weight: 400;
      color: #6E675B;
      font-size: 14px;
      outline: none;
      -webkit-transition: width 0.3s cubic-bezier(1, 0, 0, 0), padding 0.8s ease;
      transition: width 0.3s cubic-bezier(1, 0, 0, 0), padding 0.8s ease; }
      @media (max-width: 959px) {
        .knowledgehub-tabs .tab-container .search-bar {
          width: calc(100% - 62px);
          height: 62px; } }
      @media (min-width: 960px) {
        .knowledgehub-tabs .tab-container .search-bar.active {
          padding: 20px;
          visibility: visible;
          height: 100%;
          width: calc(100% - 62px);
          -webkit-transition: width 0.4s cubic-bezier(1, 0, 0, 0), padding 0.8s ease;
          transition: width 0.4s cubic-bezier(1, 0, 0, 0), padding 0.8s ease; } }
    .knowledgehub-tabs .tab-container .tab {
      position: relative;
      height: 100%;
      text-align: center;
      padding: 20px 50px;
      background-color: #ffffff;
      border-right: 1px solid #D3EDEF;
      border-top: none;
      border-bottom: none;
      border-left: none;
      box-sizing: border-box;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: calc(26/14);
      font-weight: 400;
      text-transform: uppercase;
      color: #817B71;
      -webkit-transition: .2s ease;
      transition: .2s ease;
      text-decoration: none; }
      @media (max-width: 959px) {
        .knowledgehub-tabs .tab-container .tab {
          width: 100%;
          border: none;
          text-align: left;
          padding: 20px; } }
      @media (min-width: 960px) {
        .knowledgehub-tabs .tab-container .tab.home {
          display: none; } }
      .knowledgehub-tabs .tab-container .tab:last-child {
        border-right: none; }
      .knowledgehub-tabs .tab-container .tab:hover, .knowledgehub-tabs .tab-container .tab.active {
        background-color: #004F6E;
        color: #ffffff; }
    .knowledgehub-tabs .tab-container .show-filters {
      display: flex;
      justify-content: space-between; }
      @media (min-width: 960px) {
        .knowledgehub-tabs .tab-container .show-filters {
          display: none; } }
      .knowledgehub-tabs .tab-container .show-filters:after {
        content: '';
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMjIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93RG93bk1pbmsiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iRmlsbC0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS4wMDAwMDAsIDUuNTAwMDAwKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2LjMyNDI4NjkyIC01LjUgNS41IC00LjY3NDQyMTAzIDE0Ljc3NjAyOTkgNS41IDUuNSAxNS42NzQ0MjEgNi4zMjQyODY5MiAxNi41IDE2LjUgNS41NzUzNjMyOCI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center;
        width: 18px;
        height: 18px;
        display: block; }
      .knowledgehub-tabs .tab-container .show-filters.open:after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMjIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93VXBNaW5rIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkZpbGwtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCA1LjUwMDAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2LjMyNDI4NjkyIC01LjUgNS41IC00LjY3NDQyMTAzIDE0Ljc3NjAyOTkgNS41IDUuNSAxNS42NzQ0MjEgNi4zMjQyODY5MiAxNi41IDE2LjUgNS41NzUzNjMyOCI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==); }
