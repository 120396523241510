/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.post-list {
  padding-bottom: 70px; }
  .post-list .filter-select-container {
    padding: 60px 0;
    border-bottom: 4px solid #D3EDEF;
    margin-bottom: 50px;
    text-align: right; }
    @media (max-width: 959px) {
      .post-list .filter-select-container {
        padding: 0;
        display: none; } }
  .post-list > .title {
    font-family: 'SignaSerifPro', serif;
    font-weight: 600;
    font-size: 32px;
    line-height: calc(32/32);
    color: #004F6E;
    margin-bottom: 45px; }
    @media (max-width: 1079px) {
      .post-list > .title {
        font-size: 28px; } }
    @media (max-width: 549px) {
      .post-list > .title {
        font-size: 23px; } }
  .post-list .posts {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 1079px) {
      .post-list .posts {
        justify-content: space-between; } }
