/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.knowledge-hub-recents {
  padding-top: 50px;
  padding-bottom: 50px; }
  @media (max-width: 959px) {
    .knowledge-hub-recents {
      padding-top: 70px;
      padding-bottom: 20px; } }
  .knowledge-hub-recents .wrap {
    display: flex;
    flex-direction: row; }
    @media (max-width: 1079px) {
      .knowledge-hub-recents .wrap {
        flex-direction: column; } }
    .knowledge-hub-recents .wrap .recent-articles {
      flex: 6 1;
      margin-right: 20px; }
      @media (max-width: 959px) {
        .knowledge-hub-recents .wrap .recent-articles {
          margin-right: 0; } }
      .knowledge-hub-recents .wrap .recent-articles > .title {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 32px;
        line-height: calc(32/32);
        color: #004F6E;
        margin-bottom: 30px; }
        @media (max-width: 1079px) {
          .knowledge-hub-recents .wrap .recent-articles > .title {
            font-size: 28px; } }
        @media (max-width: 549px) {
          .knowledge-hub-recents .wrap .recent-articles > .title {
            font-size: 23px; } }
        @media (max-width: 549px) {
          .knowledge-hub-recents .wrap .recent-articles > .title {
            max-width: 50%;
            line-height: 1.3; } }
      .knowledge-hub-recents .wrap .recent-articles .posts {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        @media (max-width: 959px) {
          .knowledge-hub-recents .wrap .recent-articles .posts {
            justify-content: space-between; } }
        @media (max-width: 749px) {
          .knowledge-hub-recents .wrap .recent-articles .posts .post {
            width: 100% !important;
            margin-right: 0; } }
        @media (min-width: 960px) {
          .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(1) {
            width: 100%;
            margin-right: 0; }
            .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(1) .featured-image {
              height: 500px; } }
        @media (max-width: 959px) {
          .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(1) {
            width: calc(50% - 10px); } }
        .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(2), .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(3) {
          width: calc((100% / 2) - (20px / 2)); }
          @media (max-width: 959px) {
            .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(2), .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(3) {
              width: calc(50% - 10px); } }
        .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(4), .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(5), .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(6) {
          width: calc((100% / 3) - ((20px * 2) / 3)); }
          @media (max-width: 959px) {
            .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(4), .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(5), .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(6) {
              width: calc(50% - 10px); } }
        @media (max-width: 959px) {
          .knowledge-hub-recents .wrap .recent-articles .posts .post:nth-child(2n) {
            margin-right: 0; } }
    .knowledge-hub-recents .wrap .recent-publications {
      flex: 2 1; }
      .knowledge-hub-recents .wrap .recent-publications .publications {
        background-color: #EEECEA;
        margin-bottom: 20px;
        padding: 30px 20px; }
        .knowledge-hub-recents .wrap .recent-publications .publications > h3 {
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 20px;
          line-height: calc(32/20);
          color: #004F6E;
          padding-bottom: 20px;
          border-bottom: 1px solid #ffffff; }
          @media (max-width: 1079px) {
            .knowledge-hub-recents .wrap .recent-publications .publications > h3 {
              font-size: 18px; } }
        .knowledge-hub-recents .wrap .recent-publications .publications:nth-child(even) {
          background-color: #D3EDEF; }
        .knowledge-hub-recents .wrap .recent-publications .publications.popular-topics a {
          display: block;
          font-family: 'Karla', sans-serif;
          font-size: 14px;
          letter-spacing: 2px;
          line-height: calc(26/14);
          font-weight: 400;
          text-transform: uppercase;
          color: #817B71;
          letter-spacing: 1px;
          margin-bottom: 5px; }
        .knowledge-hub-recents .wrap .recent-publications .publications .publication {
          position: relative;
          padding: 15px 0;
          border-bottom: 1px solid #ffffff; }
          .knowledge-hub-recents .wrap .recent-publications .publications .publication:after {
            content: '';
            position: absolute;
            bottom: -1.5px;
            left: 0;
            min-width: 0;
            height: 3px;
            background-color: #ffffff;
            -webkit-transition: min-width .4s ease;
            transition: min-width .4s ease; }
          .knowledge-hub-recents .wrap .recent-publications .publications .publication:hover:after {
            min-width: 100%; }
          .knowledge-hub-recents .wrap .recent-publications .publications .publication:hover .title:after {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzdweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzcgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29uL01lbnVfQXJyb3dSaWdodFdoaXRlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IvCfko4tU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkRlc2t0b3AvQ2FyZWVycy9Qb3NpdGlvbnNBdmFpbGFibGVfSG92ZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzI4LjAwMDAwMCwgLTU3OS4wMDAwMDApIiBmaWxsPSIjODE3QjcxIiBzdHJva2U9IiM4MTdCNzEiIHN0cm9rZS13aWR0aD0iMC41Ij4KICAgICAgICAgICAgPGcgaWQ9Ikljb24vTWVudV9BcnJvd1JpZ2h0U3RvbmVfSG92ZXJTdGF0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMyOS4wMDAwMDAsIDU3OS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+); }
          .knowledge-hub-recents .wrap .recent-publications .publications .publication.video {
            padding: 25px 0; }
            .knowledge-hub-recents .wrap .recent-publications .publications .publication.video .title:after {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 1px solid #D9D9D9;
              background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCA3IDEyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Mi41ICg2NzQ2OSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+Y2hldnJvbi1yaWdodDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJjaGV2cm9uLXJpZ2h0IiBmaWxsPSIjODE3QjcxIiBzdHJva2U9IiM4MTdCNzEiIHN0cm9rZS13aWR0aD0iMC42Ij4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkZpbGwtMSIgcG9pbnRzPSIxLjMyNzQ3MDUzIDAuOTMxNjQxODMgMC45NTI3OTQ2NiAxLjMxMjAzNTQ2IDQuOTM4NDY3MSA1Ljc2NTcwNDggNC45Mzg0NjcxIDYuMzAzNzQzOSAwLjk1Mjc5NDY2IDEwLjY4Nzk2NDUgMS4zMjc0NzA1MyAxMS4wNjgzNTgyIDUuOTUyNzk0NyA2LjAzNDcyNDQiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); }
          .knowledge-hub-recents .wrap .recent-publications .publications .publication .meta {
            margin-bottom: 10px; }
            .knowledge-hub-recents .wrap .recent-publications .publications .publication .meta p {
              font-family: 'Karla', sans-serif;
              font-size: 14px;
              letter-spacing: 2px;
              line-height: calc(26/14);
              font-weight: 400;
              text-transform: uppercase;
              color: #817B71;
              font-size: 12px;
              margin-bottom: 0;
              line-height: 1.2; }
              .knowledge-hub-recents .wrap .recent-publications .publications .publication .meta p.author {
                font-weight: 700; }
          .knowledge-hub-recents .wrap .recent-publications .publications .publication .title {
            position: relative;
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 16px;
            line-height: calc(32/16);
            color: #004F6E;
            display: block;
            line-height: 1.4;
            padding-right: 50px;
            text-decoration: none; }
            .knowledge-hub-recents .wrap .recent-publications .publications .publication .title:after {
              content: '';
              position: absolute;
              top: 50%;
              -webkit-transform: translateY(-50%);
                      transform: translateY(-50%);
              right: 0;
              width: 40px;
              height: 40px;
              background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMzUgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93UmlnaHRTdG9uZSIgZmlsbD0iIzgxN0I3MSI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjQuODM3MDg1NCAwIDI0LjAxMzgzNTIgMC44MjU1Nzg5NjcgMzIuNzcxMjg2IDEwLjQ5MTUwMjYgMCAxMC40OTE1MDI2IDAgMTEuNjU5MjIzOSAzMi43NzEyODYgMTEuNjU5MjIzOSAyNC4wMTM4MzUyIDIxLjE3NDQyMSAyNC44MzcwODU0IDIyIDM1IDExLjA3NTM2MzMiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
              background-position: center;
              background-repeat: no-repeat; }
