/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.call-to-action-pair .call-to-actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden; }
  .call-to-action-pair .call-to-actions .call-to-action {
    flex: 1 1;
    display: flex;
    overflow: hidden; }
    @media (max-width: 1339px) {
      .call-to-action-pair .call-to-actions .call-to-action {
        padding: 80px 35px; } }
    @media (max-width: 1079px) {
      .call-to-action-pair .call-to-actions .call-to-action {
        min-width: 100%; } }
    @media (max-width: 549px) {
      .call-to-action-pair .call-to-actions .call-to-action {
        padding: 50px 15px 70px; } }
    .call-to-action-pair .call-to-actions .call-to-action.white {
      background-color: #ffffff; }
    .call-to-action-pair .call-to-actions .call-to-action.off-white {
      background-color: #F2F2F2; }
    .call-to-action-pair .call-to-actions .call-to-action.primary {
      background-color: #004F6E;
      color: #ffffff; }
      .call-to-action-pair .call-to-actions .call-to-action.primary .outer-container .title {
        color: #3591B6; }
      .call-to-action-pair .call-to-actions .call-to-action.primary .outer-container .inner-container .headline {
        color: #ffffff; }
      .call-to-action-pair .call-to-actions .call-to-action.primary .outer-container .inner-container .body-text {
        color: #ffffff; }
    .call-to-action-pair .call-to-actions .call-to-action.tertiary {
      background-color: #D3EDEF; }
      .call-to-action-pair .call-to-actions .call-to-action.tertiary .outer-container .inner-container .headline {
        color: #004F6E; }
    .call-to-action-pair .call-to-actions .call-to-action:nth-child(odd) .outer-container {
      max-width: 700px;
      margin-left: auto;
      position: relative;
      left: -10px;
      -webkit-transition: left 0.8s;
      transition: left 0.8s; }
      .call-to-action-pair .call-to-actions .call-to-action:nth-child(odd) .outer-container.visible {
        left: 0; }
      @media (max-width: 1079px) {
        .call-to-action-pair .call-to-actions .call-to-action:nth-child(odd) .outer-container {
          margin: 0 auto; } }
    .call-to-action-pair .call-to-actions .call-to-action:nth-child(even) .outer-container {
      max-width: 700px;
      margin-right: auto;
      position: relative;
      right: -10px;
      -webkit-transition: right 0.8s;
      transition: right 0.8s; }
      .call-to-action-pair .call-to-actions .call-to-action:nth-child(even) .outer-container.visible {
        right: 0; }
      @media (max-width: 1079px) {
        .call-to-action-pair .call-to-actions .call-to-action:nth-child(even) .outer-container {
          margin: 0 auto; } }
    .call-to-action-pair .call-to-actions .call-to-action .outer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .call-to-action-pair .call-to-actions .call-to-action .outer-container .title {
        display: block;
        font-family: 'Karla', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: calc(26/14);
        font-weight: 400;
        text-transform: uppercase;
        color: #817B71;
        text-transform: uppercase;
        margin-bottom: 25px; }
      .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container {
        width: 100%;
        height: 100%;
        max-width: 540px;
        min-height: 700px;
        margin: 0px 20px;
        padding: 70px 60px;
        border: 2px solid #ffffff;
        position: relative; }
        @media (max-width: 1339px) {
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container {
            max-width: 100%;
            margin: 0; } }
        @media (max-width: 549px) {
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container {
            padding: 50px 40px;
            min-height: 0; } }
        .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic {
          height: 200px;
          width: 200px; }
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic img {
            -o-object-fit: contain !important;
               object-fit: contain !important; }
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.inline {
            width: 158px;
            height: 220px;
            margin: 0 auto 40px;
            position: relative; }
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.bottom-right, .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.bottom-left {
            position: absolute;
            height: 140px;
            width: 160px;
            top: auto;
            bottom: 60px;
            z-index: 1; }
            @media (max-width: 549px) {
              .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.bottom-right, .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.bottom-left {
                position: static;
                width: 100%;
                margin-top: 30px; } }
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.bottom-right {
            left: auto;
            right: -20px;
            -webkit-transform: translateX(50%);
                    transform: translateX(50%);
            opacity: 0;
            -webkit-transition: right 1s, opacity 0.8s;
            transition: right 1s, opacity 0.8s; }
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.bottom-right.visible {
              right: 0;
              opacity: 1; }
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .graphic.bottom-left {
            right: auto;
            left: 0;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%); }
        .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .headline {
          font-family: 'SignaSerifPro', serif;
          font-weight: 600;
          font-size: 36px;
          line-height: calc(48/36);
          color: #6E675B;
          margin-bottom: 30px; }
          @media (max-width: 1339px) {
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .headline {
              font-size: 32px; } }
          @media (max-width: 1079px) {
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .headline {
              font-size: 30px; } }
          @media (max-width: 959px) {
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .headline {
              font-size: 28px; } }
          @media (max-width: 749px) {
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .headline {
              font-size: 26px; } }
          @media (max-width: 549px) {
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .headline {
              font-size: 23px; } }
        .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .body-text {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: calc(24/16);
          font-weight: 400;
          color: #6E675B;
          margin-bottom: 40px;
          padding: 0 50px; }
          @media (max-width: 549px) {
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .body-text {
              font-size: 14px; } }
          @media (max-width: 549px) {
            .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .body-text {
              padding: 0; } }
        @media (max-width: 549px) {
          .call-to-action-pair .call-to-actions .call-to-action .outer-container .inner-container .styled-button {
            width: 100%; } }
