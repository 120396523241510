/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.team-member-grid-block {
  margin-top: 100px;
  margin-bottom: 100px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #ffffff), color-stop(50%, #F2F2F2));
  background-image: linear-gradient(90deg, #ffffff 50%, #F2F2F2 50%); }
  @media (max-width: 1079px) {
    .team-member-grid-block {
      margin-top: 0px;
      margin-bottom: 0px; } }
  @media (max-width: 1199px) {
    .team-member-grid-block.left .wrap .left-col .image {
      left: -20px !important; } }
  @media (max-width: 1079px) {
    .team-member-grid-block.left .wrap .left-col .image {
      left: 0 !important; } }
  .team-member-grid-block.right {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #F2F2F2), color-stop(50%, #ffffff));
    background-image: linear-gradient(90deg, #F2F2F2 50%, #ffffff 50%); }
    .team-member-grid-block.right .wrap {
      flex-direction: row-reverse;
      background-image: -webkit-gradient(linear, left top, right top, color-stop(90%, #F2F2F2), color-stop(90%, #ffffff));
      background-image: linear-gradient(90deg, #F2F2F2 90%, #ffffff 90%); }
      .team-member-grid-block.right .wrap .left-col .image {
        left: auto;
        right: -70px; }
        @media (max-width: 1079px) {
          .team-member-grid-block.right .wrap .left-col .image {
            right: 0; } }
  .team-member-grid-block.no-image {
    margin-top: 0; }
    .team-member-grid-block.no-image .wrap {
      background: #F2F2F2; }
      .team-member-grid-block.no-image .wrap .right-col {
        padding-top: 90px;
        padding-left: 140px;
        padding-right: 140px; }
        @media (max-width: 1339px) {
          .team-member-grid-block.no-image .wrap .right-col {
            padding-left: 80px;
            padding-right: 80px; } }
        @media (max-width: 1079px) {
          .team-member-grid-block.no-image .wrap .right-col {
            padding-left: 40px;
            padding-right: 40px; } }
        @media (max-width: 549px) {
          .team-member-grid-block.no-image .wrap .right-col {
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px; } }
        @media (max-width: 399px) {
          .team-member-grid-block.no-image .wrap .right-col {
            padding-left: 0;
            padding-right: 0; } }
  .team-member-grid-block .wrap {
    display: flex;
    flex-direction: row;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #ffffff), color-stop(10%, #F2F2F2));
    background-image: linear-gradient(90deg, #ffffff 10%, #F2F2F2 10%); }
    @media (max-width: 1079px) {
      .team-member-grid-block .wrap {
        flex-direction: column !important;
        background: #F2F2F2 !important;
        padding: 0; } }
    .team-member-grid-block .wrap .left-col {
      position: relative;
      display: flex;
      flex: 1 1; }
      .team-member-grid-block .wrap .left-col .image {
        position: relative;
        width: 100%;
        top: -110px;
        left: -70px;
        min-height: 800px;
        max-height: 850px; }
        @media (max-width: 1199px) {
          .team-member-grid-block .wrap .left-col .image {
            left: 20px !important; } }
        @media (max-width: 1079px) {
          .team-member-grid-block .wrap .left-col .image {
            top: 0;
            left: 0;
            left: 0 !important;
            min-height: 500px;
            max-height: 500px; } }
        .team-member-grid-block .wrap .left-col .image img {
          -o-object-position: top center !important;
             object-position: top center !important; }
    .team-member-grid-block .wrap .right-col {
      flex: 1 1;
      padding-top: 170px;
      padding-bottom: 100px; }
      @media (max-width: 1079px) {
        .team-member-grid-block .wrap .right-col {
          padding-top: 60px;
          padding-bottom: 60px; } }
      .team-member-grid-block .wrap .right-col .body-text {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B;
        padding-left: 20px;
        padding-right: 20px; }
        @media (max-width: 549px) {
          .team-member-grid-block .wrap .right-col .body-text {
            font-size: 14px; } }
        .team-member-grid-block .wrap .right-col .body-text h4 {
          font-size: 20px; }
