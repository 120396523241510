/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.thank-you-page .wrap .thank-you-message {
  padding: 70px 0; }
  .thank-you-page .wrap .thank-you-message h2 {
    font-family: 'SignaSerifPro', serif;
    font-weight: 600;
    font-size: 36px;
    line-height: calc(48/36);
    color: #004F6E;
    max-width: 70%;
    margin-bottom: 30px; }
    @media (max-width: 1339px) {
      .thank-you-page .wrap .thank-you-message h2 {
        font-size: 32px; } }
    @media (max-width: 1079px) {
      .thank-you-page .wrap .thank-you-message h2 {
        font-size: 30px; } }
    @media (max-width: 959px) {
      .thank-you-page .wrap .thank-you-message h2 {
        font-size: 28px; } }
    @media (max-width: 749px) {
      .thank-you-page .wrap .thank-you-message h2 {
        font-size: 26px; } }
    @media (max-width: 549px) {
      .thank-you-page .wrap .thank-you-message h2 {
        font-size: 23px; } }
  .thank-you-page .wrap .thank-you-message p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: calc(24/16);
    font-weight: 400;
    color: #6E675B;
    font-size: 18px; }
    @media (max-width: 549px) {
      .thank-you-page .wrap .thank-you-message p {
        font-size: 14px; } }
