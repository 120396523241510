/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.post {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc((100% / 3) - ((20px * 2) / 3));
  margin-right: 20px;
  margin-bottom: 30px; }
  @media (max-width: 1079px) {
    .post {
      width: calc(50% - 10px);
      margin-right: 0; } }
  @media (max-width: 749px) {
    .post {
      width: 100%; } }
  .post:nth-child(3n) {
    margin-right: 0; }
  .post .meta {
    font-family: 'Karla', sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: calc(26/14);
    font-weight: 400;
    text-transform: uppercase;
    color: #817B71;
    margin-bottom: 15px;
    padding: 0 20px;
    line-height: 1; }
    .post .meta p {
      margin-bottom: 0;
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%; }
      @media (max-width: 549px) {
        .post .meta p {
          display: inline-block;
          line-height: 1; } }
    .post .meta .author {
      font-weight: 700; }
  .post .container {
    flex: 100% 1;
    position: relative;
    padding: 0 20px 20px; }
    @media (max-width: 549px) {
      .post .container {
        padding: 0 14px 20px; } }
    .post .container:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      height: calc(100% - 115px);
      border: 2px solid #D9D9D9;
      z-index: 0; }
    .post .container > * {
      position: relative;
      z-index: 1; }
    .post .container .featured-image {
      position: relative;
      display: flex;
      width: 100%;
      height: 230px;
      margin: 0 auto 20px; }
    .post .container .title {
      margin-bottom: 15px;
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 20px;
      line-height: calc(32/20);
      color: #004F6E; }
      @media (max-width: 1079px) {
        .post .container .title {
          font-size: 18px; } }
      @media (max-width: 549px) {
        .post .container .title {
          margin-bottom: 0px; } }
      .post .container .title a {
        font-family: 'SignaSerifPro', serif;
        font-weight: 600;
        font-size: 20px;
        line-height: calc(32/20);
        color: #004F6E; }
        @media (max-width: 1079px) {
          .post .container .title a {
            font-size: 18px; } }
    .post .container .excerpt {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: calc(24/16);
      font-weight: 400;
      color: #6E675B; }
      @media (max-width: 549px) {
        .post .container .excerpt {
          font-size: 14px; } }
    .post .container .styled-button {
      margin-top: 20px; }
