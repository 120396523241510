/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.team-member-achievements {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #D3EDEF), color-stop(50%, #ffffff));
  background-image: linear-gradient(90deg, #D3EDEF 50%, #ffffff 50%); }
  @media (max-width: 1079px) {
    .team-member-achievements {
      background: #D3EDEF; } }
  .team-member-achievements .wrap {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(90%, #D3EDEF), color-stop(90%, #ffffff));
    background-image: linear-gradient(90deg, #D3EDEF 90%, #ffffff 90%);
    padding: 90px 20px; }
    @media (max-width: 1079px) {
      .team-member-achievements .wrap {
        background: #D3EDEF; } }
    @media (max-width: 549px) {
      .team-member-achievements .wrap {
        padding: 50px 20px 70px; } }
    .team-member-achievements .wrap > .title {
      margin-bottom: 50px;
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 32px;
      line-height: calc(32/32);
      color: #004F6E; }
      @media (max-width: 1079px) {
        .team-member-achievements .wrap > .title {
          font-size: 28px; } }
      @media (max-width: 549px) {
        .team-member-achievements .wrap > .title {
          font-size: 23px; } }
      @media (max-width: 1079px) {
        .team-member-achievements .wrap > .title {
          margin-bottom: 20px; } }
    .team-member-achievements .wrap .achievements {
      position: relative; }
      .team-member-achievements .wrap .achievements .achievement {
        padding: 15px 0;
        border-bottom: 1px solid #ffffff;
        max-width: 64%;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: 400;
        color: #6E675B; }
        @media (max-width: 549px) {
          .team-member-achievements .wrap .achievements .achievement {
            font-size: 14px; } }
        @media (max-width: 549px) {
          .team-member-achievements .wrap .achievements .achievement {
            max-width: none; } }
        .team-member-achievements .wrap .achievements .achievement .title {
          font-weight: 700; }
        .team-member-achievements .wrap .achievements .achievement .description {
          margin-bottom: 0; }
