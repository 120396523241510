/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.quiz-intro {
  padding: 130px 0 180px; }
  @media (max-width: 549px) {
    .quiz-intro {
      padding: 60px 0 100px; } }
  .quiz-intro .wrap {
    max-width: 1100px; }
    @media (max-width: 1199px) {
      .quiz-intro .wrap {
        max-width: 85%; } }
    @media (max-width: 549px) {
      .quiz-intro .wrap {
        max-width: none; } }
    .quiz-intro .wrap .quiz-intro-content h1, .quiz-intro .wrap .quiz-intro-content h2, .quiz-intro .wrap .quiz-intro-content h3, .quiz-intro .wrap .quiz-intro-content h4, .quiz-intro .wrap .quiz-intro-content h5, .quiz-intro .wrap .quiz-intro-content h6 {
      font-family: 'SignaSerifPro', serif;
      font-weight: 600;
      font-size: 46px;
      line-height: calc(56/46);
      color: #004F6E;
      margin-bottom: 30px; }
      @media (max-width: 1339px) {
        .quiz-intro .wrap .quiz-intro-content h1, .quiz-intro .wrap .quiz-intro-content h2, .quiz-intro .wrap .quiz-intro-content h3, .quiz-intro .wrap .quiz-intro-content h4, .quiz-intro .wrap .quiz-intro-content h5, .quiz-intro .wrap .quiz-intro-content h6 {
          font-size: 38px; } }
      @media (max-width: 1079px) {
        .quiz-intro .wrap .quiz-intro-content h1, .quiz-intro .wrap .quiz-intro-content h2, .quiz-intro .wrap .quiz-intro-content h3, .quiz-intro .wrap .quiz-intro-content h4, .quiz-intro .wrap .quiz-intro-content h5, .quiz-intro .wrap .quiz-intro-content h6 {
          font-size: 34px; } }
      @media (max-width: 959px) {
        .quiz-intro .wrap .quiz-intro-content h1, .quiz-intro .wrap .quiz-intro-content h2, .quiz-intro .wrap .quiz-intro-content h3, .quiz-intro .wrap .quiz-intro-content h4, .quiz-intro .wrap .quiz-intro-content h5, .quiz-intro .wrap .quiz-intro-content h6 {
          font-size: 28px; } }
      @media (max-width: 749px) {
        .quiz-intro .wrap .quiz-intro-content h1, .quiz-intro .wrap .quiz-intro-content h2, .quiz-intro .wrap .quiz-intro-content h3, .quiz-intro .wrap .quiz-intro-content h4, .quiz-intro .wrap .quiz-intro-content h5, .quiz-intro .wrap .quiz-intro-content h6 {
          font-size: 26px; } }
      @media (max-width: 549px) {
        .quiz-intro .wrap .quiz-intro-content h1, .quiz-intro .wrap .quiz-intro-content h2, .quiz-intro .wrap .quiz-intro-content h3, .quiz-intro .wrap .quiz-intro-content h4, .quiz-intro .wrap .quiz-intro-content h5, .quiz-intro .wrap .quiz-intro-content h6 {
          font-size: 22px; } }
    .quiz-intro .wrap .quiz-intro-content em {
      font-size: .9em; }
    .quiz-intro .wrap .start-button {
      cursor: pointer; }
