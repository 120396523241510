/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.social-link {
  display: block; }
  .social-link.light svg {
    fill: #ffffff; }
    .social-link.light svg g {
      fill: #ffffff; }
    .social-link.light svg mask {
      fill: #ffffff; }
  .social-link.dark svg {
    fill: #817B71;
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease; }
    .social-link.dark svg g {
      fill: #817B71;
      -webkit-transition: fill .3s ease;
      transition: fill .3s ease; }
    .social-link.dark svg mask {
      fill: #817B71;
      -webkit-transition: fill .3s ease;
      transition: fill .3s ease; }
  .social-link.dark:hover svg {
    fill: #004F6E; }
    .social-link.dark:hover svg g {
      fill: #004F6E; }
    .social-link.dark:hover svg mask {
      fill: #004F6E; }
  .social-link.small svg {
    height: 20px;
    width: 20px; }
  .social-link.small.google-plus svg {
    height: 26px;
    width: 26px; }
  .social-link.medium svg {
    height: 35px;
    width: 35px; }
