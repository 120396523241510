/*=============================================>>>>>
= Resources (variables, mixins... just SASS) =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Icon Helper (for use with IcoMoon or other icon fonts) =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Hamburger =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Overflow Y =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.header-nav {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-height: 0;
  max-height: 0;
  visibility: hidden;
  z-index: -1;
  padding: 30px 0;
  background: #004F6E; }
  .header-nav.active {
    display: block;
    visibility: visible;
    min-height: calc(100vh - 100%);
    max-height: calc(100vh - 100%);
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: max-height 0.3s ease, min-height 0.3s ease, opacity 0.3s 0.3s ease;
    transition: max-height 0.3s ease, min-height 0.3s ease, opacity 0.3s 0.3s ease;
    z-index: 5; }
    @media (max-width: 1079px) {
      .header-nav.active {
        padding-bottom: 150px; } }
    .header-nav.active .wrap {
      opacity: 1;
      -webkit-transition: max-height 0.3s ease, background 0.3s ease, opacity 0.3s 0.3s ease;
      transition: max-height 0.3s ease, background 0.3s ease, opacity 0.3s 0.3s ease;
      overflow-y: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch; }
  .header-nav .wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    opacity: 0; }
    @media (max-width: 1079px) {
      .header-nav .wrap {
        flex-direction: column-reverse;
        padding: 0 30px; } }
    .header-nav .wrap .nav-cols {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap; }
      @media (max-width: 1079px) {
        .header-nav .wrap .nav-cols {
          width: 100%;
          flex-direction: column;
          justify-content: flex-start; } }
      .header-nav .wrap .nav-cols .nav-col {
        display: flex;
        flex-direction: column;
        width: 21%;
        padding-right: 40px;
        margin-bottom: 20px; }
        @media (max-width: 1079px) {
          .header-nav .wrap .nav-cols .nav-col {
            width: 100% !important;
            padding-right: 0px;
            margin-bottom: 0px; } }
        .header-nav .wrap .nav-cols .nav-col:nth-child(5n + 1) {
          width: 16%; }
        .header-nav .wrap .nav-cols .nav-col .nav-link {
          position: relative; }
          .header-nav .wrap .nav-cols .nav-col .nav-link > a {
            font-family: 'SignaSerifPro', serif;
            font-weight: 600;
            font-size: 20px;
            line-height: calc(32/20);
            color: #6E675B;
            color: #ffffff;
            display: block;
            margin-bottom: 18px;
            line-height: 1.6em; }
            @media (max-width: 1079px) {
              .header-nav .wrap .nav-cols .nav-col .nav-link > a {
                font-size: 18px; } }
            @media (max-width: 1079px) {
              .header-nav .wrap .nav-cols .nav-col .nav-link > a {
                margin: 10px 0 !important; } }
          .header-nav .wrap .nav-cols .nav-col .nav-link:not(:first-child) > a {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: calc(24/16);
            font-weight: 400;
            color: #6E675B;
            color: rgba(255, 255, 255, 0.5);
            display: block;
            margin-bottom: 8px; }
            @media (max-width: 549px) {
              .header-nav .wrap .nav-cols .nav-col .nav-link:not(:first-child) > a {
                font-size: 14px; } }
            @media (max-width: 1079px) {
              .header-nav .wrap .nav-cols .nav-col .nav-link:not(:first-child) > a {
                display: block;
                padding: 9px 0;
                margin: 0 !important; } }
          .header-nav .wrap .nav-cols .nav-col .nav-link.lonely-link > a {
            margin-bottom: 3px; }
          .header-nav .wrap .nav-cols .nav-col .nav-link .nav-children {
            margin-bottom: 10px; }
            @media (max-width: 1079px) {
              .header-nav .wrap .nav-cols .nav-col .nav-link .nav-children {
                display: none; } }
            .header-nav .wrap .nav-cols .nav-col .nav-link .nav-children.open {
              display: block;
              margin: 15px 0; }
            .header-nav .wrap .nav-cols .nav-col .nav-link .nav-children .nav-child.hidden {
              display: none; }
            .header-nav .wrap .nav-cols .nav-col .nav-link .nav-children .nav-child a {
              font-family: 'Roboto', sans-serif;
              font-size: 16px;
              line-height: calc(24/16);
              font-weight: 400;
              color: #6E675B;
              color: rgba(255, 255, 255, 0.5);
              display: block;
              margin-bottom: 8px; }
              @media (max-width: 549px) {
                .header-nav .wrap .nav-cols .nav-col .nav-link .nav-children .nav-child a {
                  font-size: 14px; } }
              @media (max-width: 1079px) {
                .header-nav .wrap .nav-cols .nav-col .nav-link .nav-children .nav-child a {
                  display: block;
                  padding: 9px 0;
                  margin-bottom: 0px; } }
          .header-nav .wrap .nav-cols .nav-col .nav-link .nav-expand {
            position: absolute;
            top: 14px;
            right: 0;
            font-size: 0;
            border: 0;
            background: none;
            outline: none;
            cursor: pointer; }
            @media (min-width: 1080px) {
              .header-nav .wrap .nav-cols .nav-col .nav-link .nav-expand {
                display: none; } }
            .header-nav .wrap .nav-cols .nav-col .nav-link .nav-expand:before {
              content: '';
              background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMjIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93RG93bk1pbmsiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iRmlsbC0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS4wMDAwMDAsIDUuNTAwMDAwKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2LjMyNDI4NjkyIC01LjUgNS41IC00LjY3NDQyMTAzIDE0Ljc3NjAyOTkgNS41IDUuNSAxNS42NzQ0MjEgNi4zMjQyODY5MiAxNi41IDE2LjUgNS41NzUzNjMyOCI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center;
              width: 18px;
              height: 18px;
              display: block; }
            .header-nav .wrap .nav-cols .nav-col .nav-link .nav-expand.open:before {
              background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMjIgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjEgKDY3MDQ4KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5GaWxsIDE8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i8J+Sji1TeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9NZW51X0Fycm93VXBNaW5rIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkZpbGwtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCA1LjUwMDAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtNS41MDAwMDApICIgcG9pbnRzPSI2LjMyNDI4NjkyIC01LjUgNS41IC00LjY3NDQyMTAzIDE0Ljc3NjAyOTkgNS41IDUuNSAxNS42NzQ0MjEgNi4zMjQyODY5MiAxNi41IDE2LjUgNS41NzUzNjMyOCI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==); }
